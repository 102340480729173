<template>
    <div class="w-full h-screen paddingContainer pt-7">
        <div class="bg-white border border-lightgrey rounded infoContainer overflow-y-auto">
            <div class="flex justify-center items-center mt-14">
                <img src="../assets/success.png" class="w-56"/>
            </div>
            <div class="flex justify-center items-center">
                <img src="../assets/Congratulations.svg" class="w-72"/>
            </div>
            <div class="flex justify-center items-center mt-8">
                <button @click="goToOrgTab()" class="btnprimary nextButton h-7 w-16 flex justify-center items-center" >Done</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.nextButton {
    background-color: #119BB9;
    border: 1px solid #119BB9;
    color: #ffffff;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
}
.congratulations {
    font-family: 'Niconne', sans-serif !important;
    font-size: 24px;
    text-align: center; /* Center the text */
  }
  .niconne-regular {
  font-family: "Niconne", cursive;
  font-weight: 400;
  font-style: normal;
}
.infoContainer{
    height: 84%;
}
.paddingContainer{
    padding-left: 10%;
    padding-right: 10%;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    data() :any{
        return {
            name:'aiou',
        }
    },
    methods:{
        goToOrgTab(){
            this.$router.push({ name: 'organisations' });
        }
    },
    // async mounted(){
        // setTimeout(() => {
        //     this.$router.push({ name: 'organisations' });
        // }, 5000); 
    // }
})
</script>
