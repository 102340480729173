<template>
    <div class="popup-overlay">
        <div class="popup-content">
            <slot name="header"> </slot>
            <div class="border-b border-solid mx-4 mt-1 border-lightgrey"></div>
            <slot></slot>
        </div>
    </div>
    <div class="popup-background"></div>
</template>

<style scoped>
.popup-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    overflow: hidden;  /* Hide scrollbars while the popup is active */
}

/* .popup-content {
    background-color: white;
    max-width: 80%;
    width: 100%;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 14px;
    box-sizing: border-box;
} */

.popup-content {
    background-color: white;
    max-width: 80%;
    width: 100%;
    /* height: auto; */
    height: 70vh;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 14px;
    box-sizing: border-box;
}

.popup-background {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99998;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
    props: ['popUp'],
    data(): any {
        return {
            v$: useVuelidate(),
            pop: false
        };
    },
    created() {
        this.pop = this.popUp;
    },
    beforeUnmount(){
        this.pop = !this.popUp;
        if (this.pop == false) {
            console.log("false popUp");
            document.body.style.overflow = '';
        }
    },
    watch: {
        popUp(newVal, oldVal){
            this.pop = newVal;
            console.log("this.pop", this.pop);
        },
        pop(newVal, oldVal) {
            if (newVal == true) {
                console.log("true popUp");
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    }
});
</script>
