<template>
    <footer class="footer z-99 place-items-center w-full fixed bottom-0 h-8 border-t border-solid border-lightgrey bg-white shadow-2xl" >
        <div class="flex justify-center items-center p-2">
            <h1 class="text-xs">© {{year}} Crystalline Software Technologies Pvt. Ltd</h1>
        </div>
    </footer>
</template>
<style scoped>
.w-40 {
    width: calc(100% - 170px);
}
.w-10 {
    width: calc(100% - 10px);
}
.z-99{
    z-index: 99;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '../main';
export default defineComponent({
    data():any {
        return {
            isSidebarToggle: true,
            year:''
        };
    },
    mounted() {
        const d = new Date();
        this.year = d.getFullYear();
        emitter.on('toggle-sidebar', (isToggle: any) => {
            this.isSidebarToggle = isToggle;
        });
    }
});
</script>