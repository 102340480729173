import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import defaultLayout from "@/layouts/defaultLayout.vue";
import onBoardLayout from "@/layouts/onBoardLayout.vue"
import onboarding from "@/views/onboarding.vue"
import organisations from "@/views/organisations.vue";
import organisationUserActivity from "@/views/organisationUserActivity.vue";
import startOnBoard from "@/views/startOnBoard.vue";
import processOnBoard from '@/views/processOnBoard.vue'
import finishOnBoard from '@/views/finishOnBoard.vue';
import systemsPage from '@/views/systems.vue'
import teams from "@/views/teams.vue";
import activities from '@/views/activities.vue'
import vendorsPage from '@/views/vendors.vue'
import frameworksPage from '@/views/frameworks.vue'
import completeonBoard from "@/views/completeonBoard.vue";
import onBoardActivitiesInfo from "@/views/onBoardActivitiesInfo.vue";
import onBoardSystemInformation from "@/views/onBoardSystemInformation.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/start/onboard",
        name: "start-onBoard",
        meta: {
            title: "Join Us",
            layout: onBoardLayout,
        },
        component: startOnBoard,
    },
    {
        path: "/process/onboard",
        name: "process-onBoard",
        meta: {
            title: "Join Us",
            layout: onBoardLayout,
        },
        component: processOnBoard,
    },
    {
        path: "/finish/onboard",
        name: "finish-onBoard",
        meta: {
            title: "Join Us",
            layout: onBoardLayout,
        },
        component: finishOnBoard,
    },
    {
        path: "/complete/onboard",
        name: "complete-onBoard",
        meta: {
            title: "Join Us",
            layout: onBoardLayout,
        },
        component: completeonBoard,
    },
    {
        path: "/activities",
        name: "activities",
        meta: {
            title: "activities",
            layout: defaultLayout,
        },
        component: activities,
    },
    {
        path: "/teams",
        name: "teams",
        meta: {
            title: "teams",
            layout: defaultLayout,
        },
        component: teams,
    },
    {
        path: "/systems/:systemId/onBoardSystemInformation",
        name: "onBoardSystemInformation",
        meta: {
            title: "onBoardSystemInformation",
            layout: defaultLayout,
        },
        component: onBoardSystemInformation,
    },
    {
        path: "/activities/:activityId/onBoardActivitiesInfo",
        name: "onBoardActivitiesInfo",
        meta: {
            title: "onBoardActivitiesInfo",
            layout: defaultLayout,
        },
        component: onBoardActivitiesInfo,
    },
    {
        path: "/organisation/add",
        name: "add-organisation",
        meta: {
            title: "Join Us",
            layout: defaultLayout,
        },
        component: onboarding,
    },
    {
        path: "/organisation/:organisationId?/edit",
        name: "edit-organisation",
        meta: {
            title: "Join Us",
            layout: defaultLayout,
        },
        component: onboarding,
    },
    {
        path: "/organisations",
        name: "organisations",
        meta: {
            title: "Clients Information",
            layout: defaultLayout,
        },
        component: organisations,
    },
    {
        path: "/vendors",
        name: "vendors",
        meta: {
            title: "Vendors",
            layout: defaultLayout,
        },
        component: vendorsPage,
    },
    {
        path: "/frameworks",
        name: "frameworks",
        meta: {
            title: "frameworks",
            layout: defaultLayout,
        },
        component: frameworksPage,
    },
    {
        path: "/systems",
        name: "systems",
        meta: {
            title: "Systems",
            layout: defaultLayout,
        },
        component: systemsPage,
    },
    {
        path: "/admin",
        name: "admin",
        redirect:'/organisations'
    },
    {
        path: "/organisationsactivity",
        name: "organisationsactivity",
        meta: {
            title: "Organisation User Activity",
            layout: defaultLayout,
        },
        component: organisationUserActivity,
    },
    {
        path: "/",
        name: "home",
        redirect:'/organisations'
    },
    {
        path: '/error',
        name: 'error',
        meta: {
            title: 'error',
            layout: 'empty-layout',
        },
        props:true,
        component: () => import('@/components/error.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/error',
    },

]
const router = createRouter({
    history: createWebHistory('/'),
    routes,
});



router.beforeEach(async (to, from, next) => {
    document.title=`Admin- ${to.meta.title}`
    console.log('Router check triggered', to)
    store.state.isLoading = true;
    // next()
    // if (to.path == '/org/dashboard' || to.path == '/admin' || to.path == '/admin/courses/getall' || to.path == '/') {
    //     console.log('Nav to default route with', to.query.session)
        if (to.query.session) {
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response', res.data[0]);
                if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`;
                else {
                    next();
                }
            })
        }
        normalAuthenticationCheck(next);
    // } else {
    //     normalAuthenticationCheck(next);
    // }
});

router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});

async function normalAuthenticationCheck(next: any) {
    console.log('Inside normal check')
    if (!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
        window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`;
    } else {
        const userInfo = store.getters.userInfo;
        console.log('USER INFO', userInfo)
        if (!userInfo.roles) {
            await store.dispatch('getUserInfo').then((res) => {
                next();
            });
        }
        else {
            next();
        }
    }
}



export default router;