<template>
	<div class="rootContainer" v-if="!loading">
		<component v-bind:is="layout">
	 <router-view />
		</component>
	</div>
	<div v-else>
		<spinner />
	</div>
</template>
<style  scoped>
.rootContainer {
	background-color: #F9F9FB;
	height: 100vh;
	overflow-y: hidden;
}
</style>
<script lang="ts">
import emptylayout from '@/layouts/emptyLayout.vue';
import spinner from '@/components/spinner.vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
	components: {
		spinner
	},
	computed: {
		layout() {
			return this.$route.meta.layout || emptylayout;
		},
		...mapGetters({ loading: 'loading' })
	}
});
</script>