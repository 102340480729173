<template>
    <div class="pt-3 pl-5 pr-5">
        <div class="mb-2">
            <breadCrumb :routesArray="routesArray"></breadCrumb>
        </div>
        <div class="flex justify-between items-center h-8">
            <div>
                <h1>{{this.onBoardObject?.organisationName || "organisations"}}</h1>
            </div>
            <!-- <div class="flex items-center">
                <button v-if="selectedTabIndex == 0" @click="customPopupPopup" class="btnprimary mr-3 flex items-center">Add</button>
                <button
                    class="flex filterButton"
                    @click="filtersOpen()"
                >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
            </div> -->
        </div>

        <div class="mt-3">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>

        <div class="mt-5 tabsHeight bg-white w-full border border-lightgrey pt-5 relative rounded">
            
            <div class="group-buttons px-5" v-if="isSmallScreen">
                
                <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                    <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                </div>
                <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedTabIndex === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                    <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                </div>
            </div>
            
            <div class="px-5">
                <div>
                    <div class="flex flex-row borderBottom cursor-pointer tab-container">
                        <div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold text-primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab)">
                            <div>
                                <div ref="actualIndex" class="cursor-pointer">{{ tab.displayName }}</div>
                                <div :class="{ progress: selectedTabIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="progressbarB rounded h-1"></div>
            </div>

            <div class="w-full">
                <div v-if="selectedTabIndex == 0">
					<!-- {{dummyTenentObj}}----------------
					{{onBoardObject}} -->
                    <div class="flex flex-col justify-between h-full">
						<div class="px-5 pt-2 scrollingHeight">
							<div class="w-full pr-2 h-full overflow-y-auto scrollBarColour" ref="scrollContainerOrg" >
                                <div class="row flex flex-row w-full">
                                    <div class="w-1/2">
                                        <label class="controllabel">Organisation Legal Name</label>
                                        <input
                                            v-model.trim="onBoardObject.organizationName"
                                            placeholder="Organisation Legal Name"
                                            class="inputboxstyling mt-2"
                                            @input="v$.onBoardObject.organizationName.$touch()"
                                            :class="{
                                                'is-invalid': (v$.onBoardObject.organizationName.$error || isDuplicateData.organizationName)
                                            }"
                                        />
                                        <div v-if="v$.onBoardObject.organizationName.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.onBoardObject.organizationName.required.$invalid">Required</div>
                                            <div v-else-if="v$.onBoardObject.organizationName.required && v$.onBoardObject.organizationName.restrictLegalNameCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                        </div>
                                        <div v-if="isDuplicateData.organizationName" class="text-red-500">
                                            <div class="error-text text-xs">The Organization Name already exists.</div>
                                        </div>
                                    </div>
                                    <div class="w-1/2 leftspacing">
										<label class="controllabel">Display Name</label>
										<input
											v-model.trim="onBoardObject.displayName"
											placeholder="Display Name"
											class="inputboxstyling mt-2"
											@input="v$.onBoardObject.displayName.$touch()"    
											:class="{
												'is-invalid': (v$.onBoardObject.displayName.$error || isDuplicateData.displayName)
											}"
										/>
										<div v-if="v$.onBoardObject.displayName.$error" class="text-red-500">
											<div class="error-text text-xs" v-if="v$.onBoardObject.displayName.required.$invalid">Required</div>
											<div v-else-if="v$.onBoardObject.displayName.required && v$.onBoardObject.displayName.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
										</div>
                                        <div v-if="isDuplicateData.displayName" class="text-red-500">
                                            <div class="error-text text-xs">Display Name already exists.</div>
                                        </div>
									</div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">Website</label>
                                            <input
                                                v-model.trim="onBoardObject.websiteUrl"
                                                placeholder="Website Url"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.websiteUrl.$touch()"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.websiteUrl.$error || isDuplicateData.websiteUrl)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.websiteUrl.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.websiteUrl.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.websiteUrl.required && v$.onBoardObject.websiteUrl.restrictWebsiteCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
												<div v-else-if="v$.onBoardObject.websiteUrl.required &&  v$.onBoardObject.websiteUrl.websiteValidate.$invalid" class="error-text text-xs">Invalid Website</div>
                                            </div>
                                            <div v-if="isDuplicateData.websiteUrl" class="text-red-500">
                                                <div class="error-text text-xs">websiteUrl already exists.</div>
                                            </div>
                                        </div>
										<div class="w-1/2 leftspacing">
											<label class="controllabel">Organisation Key</label>
											<input
												v-model.trim="onBoardObject.organizationKey"
												placeholder="Organisation Key"
												class="inputboxstyling mt-2"
												@input="v$.onBoardObject.organizationKey.$touch()"
												:class="{
													'is-invalid': (v$.onBoardObject.organizationKey.$error || isDuplicateData.organizationKey)
												}"
											/>
											<div v-if="v$.onBoardObject.organizationKey.$error" class="text-red-500">
												<div class="error-text text-xs" v-if="v$.onBoardObject.organizationKey.required.$invalid">Required</div>
												<div v-else-if="v$.onBoardObject.organizationKey.required && v$.onBoardObject.organizationKey.restrictOrgKeyCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
											</div>
                                            <div v-if="isDuplicateData.organizationKey" class="text-red-500">
                                                <div class="error-text text-xs">Organization Key already exists.</div>
                                            </div>
										</div>
                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">Region</label>
                                            <input
                                                v-model.trim="onBoardObject.region"
                                                placeholder="Region"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.region.$touch()"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.region.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.region.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.region.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.region.required && v$.onBoardObject.region.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2 leftspacing">
                                            <label class="controllabel">Industry Sector</label>
                                            <input
                                                v-model.trim="onBoardObject.industrySector"
                                                placeholder="Industry Sector"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.industrySector.$touch()"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.industrySector.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.industrySector.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.industrySector.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.industrySector.required && v$.onBoardObject.industrySector.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- <div>
                                    <div class="upperspacing">
                                        <label class="controllabel">Logo</label>
                                        <div class="w-auto bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden mt-2" :class="{'is-invalid': (v$.onBoardObject.logoName.$error)}">
                                            <input type="file" accept=".jpg, .jpeg, .png, .svg" ref="fileInput" class="hidden" id="upload" @change.stop="updateFileName($event)" :disabled="onBoardObject._id" />
                                            <label for="upload" class="float-left cursor-pointer flex text-black text-xs bg-lightgrey pl-2 pr-4 py-2"><img src="../assets/attachIcon.svg" class="px-2">Attach</label>
                                            <span id="fileNameSpan" class=" items-center justify-between flex" :class="(onBoardObject.logoName)?'uploadedFileNameStyles':'placeholder-styles'" v-if="!onBoardObject._id && !onBoardObject.logoName">{{ onBoardObject.logoName ? onBoardObject.logoName.name : placeholder }}</span>
                                            <span id="fileNameSpan" class=" items-center justify-between flex" :class="(onBoardObject.logoName)?'uploadedFileNameStyles':'placeholder-styles'" v-else-if="!onBoardObject.logoName || !onBoardObject.logoName.name">{{ onBoardObject.logoName ? onBoardObject.logoName : placeholder }}</span>
                                            <span id="fileNameSpan" class=" items-center justify-between flex" :class="(onBoardObject.logoName)?'uploadedFileNameStyles':'placeholder-styles'" v-else>{{ onBoardObject.logoName ? onBoardObject.logoName.name : placeholder }}</span>
                                        </div>
                                        <div v-if="v$.onBoardObject.logoName.$error" class="text-red-500">
                                            <div class="error-text text-xs mx-2" v-if="v$.onBoardObject.logoName.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="upperspacing">
                                    <div>
                                        <label class="controllabel">Address line - 1</label>
                                        <input
                                            v-model.trim="onBoardObject.addressLineOne"
                                            placeholder="Address line - 1"
                                            class="inputboxstyling mt-2"
                                            @input="v$.onBoardObject.addressLineOne.$touch()"
                                            :class="{
                                                'is-invalid': (v$.onBoardObject.addressLineOne.$error)
                                            }"
                                        />
                                        <div v-if="v$.onBoardObject.addressLineOne.$error" class="text-red-500">
                                            <div class="error-text text-xs" v-if="v$.onBoardObject.addressLineOne.required.$invalid">Required</div>
                                            <div v-else-if="v$.onBoardObject.addressLineOne.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="upperspacing">
                                    <div>
                                        <label class="controllabel">Address line - 2</label>
                                        <input
                                            v-model.trim="onBoardObject.addressLineTwo"
                                            placeholder="Address line - 2"
                                            class="inputboxstyling mt-2"
                                            @input="v$.onBoardObject.addressLineTwo.$touch()"
                                            :class="{
                                                'is-invalid': (v$.onBoardObject.addressLineTwo.$error)
                                            }"
                                        />
                                        <div v-if="v$.onBoardObject.addressLineTwo.$error" class="text-red-500">
                                            <div v-if="v$.onBoardObject.addressLineTwo.restrictCharacters300.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">City</label>
                                            <input
                                                v-model.trim="onBoardObject.city"
                                                placeholder="City"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.city.$touch()"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.city.$error)
                                                }"
                                            />
                                            <!-- <multiselect 
                                                v-model="onBoardObject.city" 
                                                :options="citiesList"
                                                :multiple="false"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                :hide-selected="true"
                                                :disabled="onBoardObject.country == ''"   
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="City"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid borderRed': v$.onBoardObject.city.$error,
                                                    'has-options': citiesList && citiesList.length > 0,
                                                    'no-options' :  !citiesList.length && onBoardObject.country
                                                }"
                                            >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No City Found</span>
                                            </template>
                                            </multiselect> -->
                                            <div v-if="v$.onBoardObject.city.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.city.required.$invalid">Required</div>
                                                <div v-if="v$.onBoardObject.city.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                                <div v-else-if="v$.onBoardObject.city.required && v$.onBoardObject.city.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2 leftspacing">
                                            <label class="controllabel">State</label>
                                            <input
                                                v-model.trim="onBoardObject.state"
                                                @input="v$.onBoardObject.state.$touch()"
                                                placeholder="State"
                                                class="inputboxstyling mt-2"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.state.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.state.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.state.required.$invalid">Required</div>
                                                <div v-if="v$.onBoardObject.state.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                                <div v-else-if="v$.onBoardObject.state.required && v$.onBoardObject.state.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">Country</label>
                                            <input
                                                v-model.trim="onBoardObject.country"
                                                @input="v$.onBoardObject.country.$touch()"
                                                placeholder="Country"
                                                class="inputboxstyling mt-2"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.country.$error)
                                                }"
                                            />
                                            <!-- <multiselect 
                                                v-model="onBoardObject.country" 
                                                :options="countriesList"
                                                :multiple="false"
                                                @select="changeCountry(onBoardObject.country)"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                open-direction="bottom"
                                                :hide-selected="true"
                                                :disabled="onBoardObject.country == ''"   
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="City"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid borderRed': v$.onBoardObject.city.$error,
                                                    'has-options': citiesList && citiesList.length > 0,
                                                    'no-options' :  !citiesList.length && onBoardObject.country
                                                }"
                                            >
                                            <template v-slot:noResult>
                                                <span class="text-xs">No City Found</span>
                                            </template>
                                            </multiselect> -->
                                            <div v-if="v$.onBoardObject.country.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.country.required.$invalid">Required</div>
                                                <div v-if="v$.onBoardObject.country.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                                <div v-else-if="v$.onBoardObject.country.required && v$.onBoardObject.country.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2 leftspacing">
                                            <label class="controllabel">Pincode</label>
                                            <input
                                                v-model.trim="onBoardObject.pinCode"
                                                type="number"
                                                placeholder="Pincode"
                                                class="inputboxstyling mt-2"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.pinCode.$error)
                                                }"
                                                @input="v$.onBoardObject.pinCode.$touch()" 
                                                
                                            />
                                            <div v-if="v$.onBoardObject.pinCode.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.pinCode.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.pinCode.required && v$.onBoardObject.pinCode.pin.$invalid" class="error-text text-xs">Pincode is Invalid</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full upperspacing">
                                        <div class="w-1/2">
                                            <label class="controllabel">Primary Contact Name</label>
                                            <input
                                                v-model.trim="onBoardObject.primaryContactName"
                                                placeholder="Primary Contact Name"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.primaryContactName.$touch()"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.primaryContactName.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.primaryContactName.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.primaryContactName.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.primaryContactName.required && v$.onBoardObject.primaryContactName.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                                <div v-else-if="v$.onBoardObject.primaryContactName.required && v$.onBoardObject.primaryContactName.textOnly.$invalid" class="text-red-500  text-xs">Only letters and spaces are allowed.</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2 leftspacing">
                                            <label class="controllabel">Primary Contact Mail Id</label>
                                            <input
                                                v-model.trim="onBoardObject.primaryContactEmail"
                                                placeholder="Primary Contact Mail id"
                                                class="inputboxstyling mt-2"
                                                @input="v$.onBoardObject.primaryContactEmail.$touch()"
                                                :class="{
                                                    'is-invalid': (v$.onBoardObject.primaryContactEmail.$error)
                                                }"
                                            />
                                            <div v-if="v$.onBoardObject.primaryContactEmail.$error" class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.onBoardObject.primaryContactEmail.required.$invalid">Required</div>
                                                <div v-else-if="v$.onBoardObject.primaryContactEmail.required && v$.onBoardObject.primaryContactEmail.email.$invalid" class="error-text text-xs">Mail Id is Invalid</div>
                                                <div v-else-if="v$.onBoardObject.primaryContactEmail.required && v$.onBoardObject.primaryContactEmail.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
						</div>
						<div class="absolute bottom-0 w-40 rounded-b-sm h-14 flex justify-end items-center bg-white" style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);" >
							<div class="buttonposition flex justify-end items-center mx-5">
								<button class="btnprimary mr-2" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="onCancel('org')">Cancel</button>
								<button class="btnprimary" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="updateOrganization(onBoardObject)">Update</button>
							</div>
						</div>
					</div>
                </div>

                <div v-else-if="selectedTabIndex == 1">
                    <div class="flex flex-col justify-between h-full">
						<div class="px-5 pt-2 scrollingHeight">
							<div class="w-full h-full pr-2 overflow-y-auto scrollBarColour">
								<div class="h-3/6 flex flex-wrap gap-10">
									<label v-for="apps in allApps" :key="apps" class="w-28 h-28 border border-lightgrey rounded" style="width: 190px; height: 140px;" @change="selectCheckBoxApps(apps)">
										<div class="h-full rounded relative flex justify-center cursor-pointer items-center">
											<input type="checkbox" class="cursor-pointer checkbox absolute top-3 left-3" v-model="selectedApps" :value="apps">
											<div class="h-full w-full flex justify-center items-center text-base uppercase font-semibold">{{apps.appName}}</div>
										</div>
									</label>
								</div>
							</div>
						</div>
						<div class="absolute bottom-0 w-40  rounded-b-sm h-14 flex justify-end items-center bg-white" style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
							<div class="buttonposition flex justify-end items-center mx-5">
                                <button :class="disableSelectedAppStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancel('apps')" class="btnprimary mr-2" >Cancel</button>
                                <button :class="disableSelectedAppStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" :disabled="clickOnButton" @click="updateSelectedApps()" class="btnprimary">Update</button>
                            </div>
						</div>
					</div>
                </div>

				<div v-else-if="selectedTabIndex == 2">
                    <div class="flex flex-col justify-between h-full">
						<div class="px-5 pt-2 scrollingHeight">
							<div class="w-full h-full pr-2 overflow-y-auto scrollBarColour">
								<div class="h-3/6 flex flex-wrap gap-10">
									<label v-for="framework in allFrameworks" :key="framework" class="w-28 h-28 border border-lightgrey rounded" style="width: 190px; height: 140px;" :class="framework?.controlFrameWork  == 'InternalControls' || framework?.name == 'InternalControls'? 'disabled pointer-events-none':''" @change="selectCheckBoxFrameworks(framework)">
										<div class="h-full rounded relative flex justify-center cursor-pointer items-center">
											<input type="checkbox" class="cursor-pointer checkbox absolute top-3 left-3" v-model="selectedFrameWorks" :value="framework">
											<div class="h-full w-full flex justify-center items-center text-base uppercase font-semibold">{{framework?.controlFrameWork || framework?.name || 'N/A'}}</div>
										</div>
									</label>
								</div>
							</div>
						</div>
						<div class="absolute bottom-0 w-40  rounded-b-sm h-14 flex justify-end items-center bg-white" style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
							<div class="buttonposition flex justify-end items-center mx-5">
                                <button :class="disableSelectedFrameworkStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancel('frameworks')" class="btnprimary mr-2" >Cancel</button>
                                <button :class="disableSelectedFrameworkStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" :disabled="clickOnButton" @click="updateSelectedFrameworks()" class="btnprimary">Update</button>
                            </div>
						</div>
					</div>
                </div>

				<div v-else-if="selectedTabIndex == 3">
                    <div class="flex flex-col justify-between">
						<div class="h-3/6 px-5">
							{{'History'}}
						</div>
						<!-- <div class="absolute bottom-0 mt-5 w-40 h-14 flex justify-end items-center" style="box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);">
							<div class="buttonposition flex justify-end items-center px-5 mx-5">
								<button @click="onCancelAllControls()" class="btnprimary mr-1.5" >Cancel</button>
								<button @click="mapTheSelectedControlsToActivity()" class="btnprimary">Save</button>
							</div>
						</div> -->
					</div>
                </div>
            </div>
            <dotsLoader v-if="dotsLoader" />
        </div>
    </div>
    
</template>

<style scoped>
.fullText{
    white-space: break-spaces;
    font-size: 12px;
}
.scrollingHeight{
	height: calc(100vh - 350px);
}
.tabsHeight{
	height: calc(100vh - 225px);
}
@media only screen and (min-width: 1607px) and  (max-width: 6000px) {
  .tabsHeight {
    height: calc(100vh - 210px);
  }
}
table {
  /* table-layout: fixed; */
  /* width: 100%; */
  background: #FFFFFF;
}
.w-40 {
    width: calc(100% - 0px);
}
.w-10 {
    width: calc(100% - 2.5rem);
}
.nonEmptyTable tr:last-child {
  border-bottom: 1px solid #e9e9e9;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 60px !important; 
}
.midHead {
    width: 160px !important;
}
.widthForSelect{
    width : 100px !important;
}
.redBorder {
	border: 1px solid red !important;
	border-radius: 5px !important;
}
.optionColors{
   color : #4D4D4D !important;
}
.borderRed {
    border: 1px solid red !important;
}
.scrollBarColour::-webkit-scrollbar,
.scrollBarColour::-webkit-scrollbar-thumb,
.scrollBarColour::-webkit-scrollbar-track { 
	width: 4px;
	border: none;
	background: transparent;
}

/* Hides additional scrollbar elements like buttons and corners */
.scrollBarColour::-webkit-scrollbar-button,
.scrollBarColour::-webkit-scrollbar-track-piece,
.scrollBarColour::-webkit-scrollbar-corner,
.scrollBarColour::-webkit-resizer {
	display: none;
}

/* Custom thumb style */
.scrollBarColour::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-color: #1A5FAC; /* Thumb color */
}

/* Custom track with image background */
.scrollBarColour::-webkit-scrollbar-track {
	background-image: url("../assets/scrollLine.svg");
	background-repeat: repeat-y;
	background-size: contain;
	filter: grayscale(100%) sepia(100%) hue-rotate(180deg) saturate(200%) brightness(80%);
}


.scrollBarColour::-webkit-scrollbar-track {
	border-radius: 6px; /* Rounded corners */
	width: 1px;
}
.scrollBarColour::-webkit-scrollbar-thumb {
	background: #119bb9; /* Darker color for the thumb */
	border-radius: 6px; /* Rounded corners */
	width: 2px;
}
.scrollBarColour::-webkit-scrollbar {
    height: 8px; /* Adjust height of the horizontal scrollbar */
}
</style>
<script lang="ts">
import _ from 'lodash';
import popup from '@/components/popup.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers, requiredIf, email } from '@vuelidate/validators';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
import { emitter, toast } from '@/main';
import store from '@/store';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import breadCrumb from '@/components/breadCrumb.vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import dotsLoader from '@/components/dotsLoader.vue';
import Multiselect from 'vue-multiselect';
const pin = helpers.regex(/^\d{5}(?:\d{1})?$/);
const restrictLegalNameCharacters = helpers.regex(/^.{0,60}$/);
const restrictOrgKeyCharacters = helpers.regex(/^.{0,5}$/);
const restrictCharacters = helpers.regex(/^.{0,30}$/);
const restrictWebsiteCharacters = helpers.regex(/^.{0,100}$/);
const restrictCharacters300 = helpers.regex(/^.{0,300}$/);
const textOnly = helpers.regex(/^[a-zA-Z\s]*$/);
const websiteValidate = helpers.regex(/^(https?:\/\/)?(www\.)?((?!www)[a-zA-Z0-9-]{2,256}\.(?!www)[a-zA-Z]{2,20}(\.(?!www)[a-zA-Z]{2,20}){0,2})(:(?:[0-5]?[0-9]{1,4}|[1-5][0-9]{1,3}|655[0-3][0-5]))?(\/[a-zA-Z0-9\-._~:/#\\]*)?$/g);

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            selectedTabObj: { displayName: 'Organisation Info', name: 'organisation', id: 0 },
            carouselHead: [
                { displayName: 'Organisation Info', name: 'organisation', id: 0 },
                { displayName: 'Apps', name: 'apps', id: 1 },
                { displayName: 'Frameworks', name: 'frameworks', id: 2 },
                { displayName: 'History', name: 'history', id: 3 },
            ],
            selectedTabIndex: null,
            showFilters: false,
            sideButton: false,
            clickOnButton: false,
            dotsLoader: false,
           
            tenants: [],
            tenantsSelect: [],
            
           
            routesArray: [],

            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',

            pageSize: 20,
            currentPage: 1,
            dynamicPagination: '',
          
            visibleTabs: [], // Tabs visible on screen
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0, // Total number of groups for small screens

            dataLoading:true,
            isSidebarToggle:true,
			dummyTenentObj: {},

            allTenants: [],

			selectedApps: [],
			selectedDummyApps: [],
            remainingApps: [],
            allApps: [],

            selectedFrameWorks: [],
            selectedDummyFrameworks: [],
            remainingFrameworks: [],
            allFrameworks: [],


			onBoardObject: {
                organizationName:"",
                organizationKey:"",
                websiteUrl:"",
                displayName:"",
                region:"",
                industrySector:"",
                // logoName:"",
                logoUrl:"",
                addressLineOne:"",
                addressLineTwo:"",
                city:"",
                state:"",
                country:"",
                pinCode:"",		
                primaryContactName:"",
                c:"",
            },
        };
    },
    components: {
        popup,
        Multiselect,
        breadCrumb,
        toolTip,
        pagination,
        dotsLoader
    },
    validations() {
        return {
            onBoardObject: {
                organizationName: { required,restrictLegalNameCharacters },
                organizationKey: { required,restrictOrgKeyCharacters },
                websiteUrl: { required ,restrictWebsiteCharacters, websiteValidate},
                displayName: { required,restrictCharacters },
                region: { required,restrictCharacters },
                industrySector: { required,restrictCharacters },
                // logoName: { required },
                addressLineOne: { required,restrictCharacters300 },
                addressLineTwo: { restrictCharacters300 },
                city: { required ,restrictCharacters,textOnly },
                state: { required,restrictCharacters,textOnly  },
                country: { required ,restrictCharacters,textOnly },
                pinCode: { required,pin },
                primaryContactName: { required,restrictCharacters,textOnly  },
                primaryContactEmail: { required , email,restrictCharacters},
            },
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo'
        }),

		disableStatus: function (): any {
			if (JSON.stringify(this.dummyTenentObj) == JSON.stringify(this.onBoardObject)) {
				return false;
			} else {
				return true;
			}
        },
        isDuplicateData: function (): any {
            if(this.allTenants.length != 0){
                return {
                    organizationName: this.allTenants.some((tenant:any) =>{ return this.onBoardObject.organizationName &&  tenant.organizationName!= undefined &&  tenant.organizationName.replace(/\s+/g, '').toLowerCase() === this.onBoardObject.organizationName.replace(/\s+/g, '').toLowerCase() }),
                    organizationKey: this.allTenants.some((tenant:any) => { return this.onBoardObject.organizationKey && tenant.organizationKey!= undefined && tenant.organizationKey.replace(/\s+/g, '').toLowerCase() === this.onBoardObject.organizationKey.replace(/\s+/g, '').toLowerCase()}),
                    websiteUrl: this.allTenants.some((tenant:any) =>{ return this.onBoardObject.websiteUrl && tenant.websiteUrl!= undefined && tenant.websiteUrl.replace(/\s+/g, '').replace(/^https?:\/\//, '').toLowerCase() === this.onBoardObject.websiteUrl.replace(/\s+/g, '').replace(/^https?:\/\//, '').toLowerCase()}),
                    displayName: this.allTenants.some((tenant:any) =>{ return this.onBoardObject.displayName && tenant.displayName!=undefined && tenant.displayName.replace(/\s+/g, '').toLowerCase() === this.onBoardObject.displayName.replace(/\s+/g, '').toLowerCase()}),
                };
            } else {
                return false
            }
        },

        disableSelectedFrameworkStatus: function (): any {
            const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
                if (arr1.length !== arr2.length) return false;
                return arr1.every((value, index) => arr2.includes(value));
            };
            return arraysEqual(this.selectedFrameWorks, this.selectedDummyFrameworks);
        },
		
		disableSelectedAppStatus: function (): any {
            const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
                if (arr1.length !== arr2.length) return false;
                return arr1.every((value, index) => arr2.includes(value));
            };
            return arraysEqual(this.selectedApps, this.selectedDummyApps);
        },
    },
    methods: {
        // handleOrgFormScroll(event:any) {
        //     const container = this.$refs.scrollContainerOrg;
        //     const clickPosition = event.clientY - container.offsetTop;
        //     const containerHeight = container.clientHeight;
        //     const scrollHeight = container.scrollHeight;
        //     const currentScrollPosition = container.scrollTop;
        //     console.log("containerHeight", containerHeight)
        //     console.log("scrollHeight", scrollHeight)
        //     if (scrollHeight > containerHeight) {
        //         console.log("clickPosition", clickPosition)
        //         console.log("currentScrollPosition", currentScrollPosition)
        //         console.log("containerHeight * 0.9", containerHeight * 0.8)
        //         if (clickPosition > containerHeight * 0.8) {
        //             console.log("INNNNNNNNN")
        //             if (currentScrollPosition + containerHeight < scrollHeight) {
        //                 container.scrollTo({
        //                     top: currentScrollPosition + containerHeight,
        //                     behavior: 'smooth'
        //                 });
        //             }
        //         } else {
        //             if (clickPosition < containerHeight * 0.2) {
        //                 console.log("OUTTTTTTT")
        //                 container.scrollTo({
        //                     top: currentScrollPosition - containerHeight, 
        //                     behavior: 'smooth'
        //                 });
        //             }
        //         }
        //     }
        // },
        onCancel(state: any){
			this.v$.$reset();
			if(state == 'org'){
				this.onBoardObject = {...this.dummyTenentObj}
			} else if (state == 'apps'){
				this.selectedApps = [...this.selectedDummyApps]
                this.remainingApps = [];
			} else if(state == 'frameworks'){
                this.selectedFrameWorks = [...this.selectedDummyFrameworks]
                this.remainingFrameworks = [];
            }
        },
        async toggleTabs(tabId: any) {
            this.dataLoading = true
            this.selectedTabIndex = tabId.id;
            sessionStorage.setItem('selectedTab', tabId.id);
            this.selectedTabObj = tabId;

            this.dataLoading = false
        },
        selectCheckBoxFrameworks(framework:any) {
            let boolean:any = false
            boolean = this.remainingFrameworks.some((row:any)=>framework._id == row._id);
            if(boolean == true){
                const indexxx = this.remainingFrameworks.findIndex((obj:any) => obj._id === framework._id);
                this.remainingFrameworks.splice(indexxx, 1);
            }else{
                this.remainingFrameworks.push(framework);
            }
            console.log("this.remainingFrameworks", this.remainingFrameworks)
        },
		selectCheckBoxApps(app:any) {
            let boolean:any = false
            boolean = this.remainingApps.some((row:any)=>app.appId == row.appId);
            if(boolean == true){
                const indexxx = this.remainingApps.findIndex((obj:any) => obj.appId === app.appId);
                this.remainingApps.splice(indexxx, 1);
            }else{
                this.remainingApps.push(app);
            }
            console.log("this.remainingApps", this.remainingApps)
        },
        async updateSelectedFrameworks(){
            let payload: any = {};
            let frameworkIds: any = [];
            this.remainingFrameworks.map((row: any)=>{
                frameworkIds.push(row._id)
            })
            payload = {
                'selectedFrameworkIds': frameworkIds
            };
            this.dotsLoader = true
			await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/organization/${this.$route.params.organisationId}/update/frameworks/edit`, payload).then(async (res:any) => {
				await this.getAllFrameworks();
                if (res.status == 200) {
                    this.dotsLoader = false;
                    toast.info(`Updated Successfully`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
			})
		},
		async updateSelectedApps(){
            this.clickOnButton = true;
            let payload: any = {};
            let appIds: any = [];
            this.remainingApps.map((row: any)=>{
                appIds.push(row.appId)
            })
            payload = {
                'appIds': appIds
            };
            this.dotsLoader = true;
			try{
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/organization/${this.$route.params.organisationId}/apps/permission/update`, payload).then(async (res:any) => {
                    if (res.status == 200) {
                        await this.getSingleOrganization();
                        this.dotsLoader = false;
                        toast.info(`Updated Successfully`, {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                    }
                })
            }
            catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false;
            }
		},
		async updateOrganization(payload: any) {
			this.v$.onBoardObject.$touch();
			delete payload._id;
            let check:any = !Object.values(this.isDuplicateData).includes(true)
			if(!this.v$.onBoardObject.$invalid && check){
				await this.$http
				.post(`${process.env.VUE_APP_ADMIN_API_URL}/organization/${this.$route.params.organisationId}/update`, payload)
				.then(async (res:any) => {
					console.log("response",res.data.errorStatus)
					if(res.data.errorStatus){
							toast.error(res.data.errorStatusData, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						// this.$router.back();
					}
					if (res.status == 200) {
						toast.info(`Organisations Updated.`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						// this.$router.back();
					}
					await this.getSingleOrganization();
				})
				.catch((err:any) => {
					toast.error(err, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			}
		},
		async getSingleOrganization() {
			this.dotsLoader = true;
            this.remainingApps = [];
            // this.selectedApps = [];
            this.selectedDummyApps = [];
			await this.$http
				.get(`${process.env.VUE_APP_AUTH_API_URL}/organization/${this.$route.params.organisationId}/get`)
				.then((res: any) => {
					console.log("res.data", res.data)
					this.onboarding = res.data[0];
					this.onboarding.organisationName = this.onboarding.organizationName
					this.onboarding.phoneNumber = this.onboarding.contactNumber
					this.onboarding.website =this.onboarding.websiteUrl
					this.onboarding.organisationLogo = this.onboarding.organizationLogo
					this.allApps=this.onboarding?.apps || []
                    this.selectedApps = [];
					this.allApps.map((obj:any)=>{
						if(obj.isActive == true){
							this.selectedApps.push(obj)
						}
					})
					this.selectedDummyApps = [...this.selectedApps]
					this.orgActive= this.onboarding.isActive
					this.dummyTenentObj = {...this.onboarding}
					this.onBoardObject = this.onboarding;
					// this.isOrgChange = false
                    this.dotsLoader = false;
				})
				.catch((error: any) => {
					this.dotsLoader = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
        async getAllFrameworks() {
            // this.selectedFrameWorks = [];
			this.dotsLoader = true;
            this.remainingFrameworks = [];
            this.selectedDummyFrameworks = [];
			await this.$http
				.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/organization/${this.$route.params.organisationId}/frameworks/getall`)
				.then((res: any) => {
                    if(res.status == 200){
                        console.log("res.data Frameworks", res.data)
                        this.allFrameworks = res.data
                        this.selectedFrameWorks = [];
                        this.allFrameworks.map((obj:any)=>{
                            if(obj.isActive == true || obj?.frameworkName == 'InternalControls' || obj?.name == 'InternalControls' || obj?.controlFrameWork == 'InternalControls' ){
                                this.selectedFrameWorks.push(obj)
                            }
                        })
                        this.selectedDummyFrameworks = [...this.selectedFrameWorks]
                    }
                    this.dotsLoader = false;
				})
				.catch((error: any) => {
					this.dotsLoader = false;
					toast.error('Error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
        getAllTenants() {
			this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((tenent: any)=>{
                    if(tenent._id != this.$route.params.organisationId){
                        this.allTenants.push(tenent)
                    }
                })
			});
            
		},
    },
    async mounted() {
        this.visibleTabs = [...this.carouselHead];

        emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isSidebarToggle = isToggle;
		});
    },
    async created() {
        this.dataLoading = true;
        await this.getAllTenants();
        await this.getSingleOrganization();
        await this.getAllFrameworks();
        // await this.getAllControls()
        
        let savedTabKey: any = sessionStorage.getItem('selectedTab');
        
        savedTabKey = parseInt(savedTabKey)
		if (savedTabKey) {
		    const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
            
            if (savedTab) {
                await this.toggleTabs(savedTab);
            }else{
                this.selectedTabObj = { displayName: 'Organisation Info', name: 'organisation', id: 0 },
                this.selectedIndex = 0;
            }
		}
		else{
			this.selectedTabObj = { displayName: 'Organisation Info', name: 'organisation', id: 0 },
			this.selectedIndex = 0;
            await this.toggleTabs(this.selectedTabObj);
		}

        this.dataLoading = false;
        
         this.routesArray = [
            { name: "Organisations", routeName: "organisations" },
            { name: this.onBoardObject?.organisationName || 'Organisation', routeName: "" },
        ];
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab');
    }
});
</script>
