<template>
	<div class="p-4">
		<h1>Organisation User Activity</h1>
		<div class="mt-4" >
			<div class="text-black no-underline mt-0.5 min-h-30 cursor-pointer" v-for="organisation in organisations" :key="organisation._id" :class="{ accordion: organisation.isToggle }">
				<div class="flex bg-white py-2.5 px-2.5 text-lg rounded cursor-pointer" :class="{ 'accordion-head': !organisation.isToggle }">
					<div class="title font-opensans font-semibold text-lg w-full flex cursor-pointer" @click="organisation.isToggle = !organisation.isToggle">
					{{ organisation.organizationName }}
				</div>
				</div>
				<div class="accordion-body  bg-white" v-if="organisation.isToggle">
					<hr class="border-t-2" />
					<div class="p-4">
						<table class="font-opensans">
						<thead>
							<tr>
								<th>Users</th>
								<th class="header cursor-pointer" v-for="app in apps" :key="app.id">
									<div>
										{{ app.appName }}
									</div>
								</th>
								<th class="flex justify-center items-center">
									<div @click="addUser()">
										<img src="@/assets/plusIcon.svg" class="h-5 w-5" />
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(user, userIndex) in usersArray" :key="user">
								<td>
									<select class="inputboxstyling" @change="selectUser($event, user)" v-model="user.selecteduserName" :disabled="user.editOption">
										<option value="0" class="disabled">please select type</option>
										<option v-for="singleuser in userData" :key="singleuser" :value="singleuser.name">{{ singleuser.name }}</option>
									</select>
								</td>
								<td>
									<select class="inputboxstyling" v-model="user.selectMonitor" :disabled="user.editOption">
										<option disabled>please select type</option>
										<option
											v-for="role in roles.filter((role) => {
												return role.moduleId == 10001;
											})"
											:value="role.roleId"
											:key="role.roleId"
										>
											{{ role.roleName }}
										</option>
									</select>
								</td>
								<td>
									<select class="inputboxstyling" v-model="user.selectTraining" :disabled="user.editOption">
										<option value="0" disabled>please select type</option>
										<option
											v-for="role in roles.filter((role) => {
												return role.moduleId == 10002;
											})"
											:value="role.roleId"
											:key="role.roleId"
										>
											{{ role.roleName }}
										</option>
									</select>
								</td>
								<td class="child-action-col">
										<div class="flex flex-row align-center">
											<div class="px-1 py-0"  v-if="!user.showSave" @click="editUser(userIndex)">
												<img src="@/assets/editIcon.svg" class="h-5 w-5" />
											</div>
											<div v-if="user.showSave" @click="onSubmit(user, organisation, user.selecteduserName,userIndex)">
												<img src="@/assets/correctIcon.svg" class="h-5 w-5" />
											</div>
											<div v-if="user.showSave"  @click="clearAllFlags(userIndex)">
												<img src="@/assets/cancel.svg" class="h-5 w-5 ml-2" />
											</div>

											<div  v-if="!user.showSave" @click="clearUser(userIndex)">
												<img src="@/assets/deleteIcon.svg " class="h-5 w-5 ml-2" />
											</div>
										</div>
									</td>
							</tr>
						</tbody>
					</table>
					</div>
				</div>
			</div>
		</div>
		<spinner v-if="isLoader" />
	</div>
</template>
<style scoped>
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { toast } from '../main';
import spinner from '@/components/spinner.vue';

export default defineComponent({
	data(): any {
		return {
			apps: [],
			isLoader: false,
			usersArray: [],
			userData: [],
			users: [],
			editOption: true,
			organisations: [],
			roles: [
				{
					roleName: 'Monitor Root Admin',
					roleId: 10201,
					moduleId: 10001
				},
				{
					roleName: 'Admin',
					roleId: 10202,
					moduleId: 10001
				},
				{
					roleName: 'Department Owner',
					roleId: 10203,
					moduleId: 10001
				},
				{
					roleName: 'Task Owner',
					roleId: 10204,
					moduleId: 10001
				},
				{
					roleName: 'User',
					roleId: 10205,
					moduleId: 10001
				},
				{
					roleName: 'Training Root Admin',
					roleId: 10206,
					moduleId: 10002,
					disabled: true
				},
				{
					roleName: 'Client Admin',
					roleId: 10207,
					moduleId: 10002
				},
				{
					roleName: 'User',
					roleId: 10208,
					moduleId: 10002
				}
			],
			selectedUser: {}
		};
	},
	computed: {},
	methods: {
		selectUser(event: any, user: any) {
			this.selectedUser = this.userData.find((user: any) => user.name == event.target.value);
			this.selectedUser.roles.forEach((role: any, index: any) => {
				this.roles.forEach((roles: any) => {
					if (role.appId == roles.moduleId && role.appId == 10001) {
						this.selectedUser.roles[index].roleId = role.roleId;
						user.selectMonitor = role.roleId;
					} else {
						this.selectedUser.roles[index].roleId = role.roleId;
						user.selectTraining = role.roleId;
					}
				});
			});
		},
		getAllTenants() {
			this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.organisations = res.data;
				this.organisations.forEach((element: any) => {
					element.isToggle = false;
				});
				this.apps = this.organisations[0].apps;
			});
		},
		clearUser(userIndex: any) {
			this.usersArray.splice(userIndex, 1);
		},
		editUser(userIndex: any) {
			this.usersArray[userIndex].editOption = false;
			this.usersArray[userIndex].showSave = true;
		},
			clearAllFlags(userIndex: any) {
			this.usersArray.splice(userIndex, 1);
		},
		addUser() {
			this.usersArray.push({ showSave: true, selectMonitor: '', selectTraining: '', selecteduserName: '' });
		},
		async getAllUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.userData = res.data;
			});
			let monitor: any, training: any, userName:any;

			this.userData.forEach((user: any) => {
				user.roles.find((role: any) => {
					if (role.roleId == 10206 || role.roleId == 10201) {
						this.users.push(user);
					}
				});
			});
			this.userData = [...new Set(this.users)];

			this.userData.forEach((user: any, index: any) => {
				user.roles.find((role: any) => {
					if (role.appId == 10001) {
						monitor = role.roleId;
					} else {
						training= role.roleId;
					}
				});
				userName = user.name;
				this.usersArray.push({ showSave: false, selectMonitor: monitor, selectTraining: training, selecteduserName: userName, editOption: true });
			});
		},
		async onSubmit(user: any, organisation: any, userName: any,userIndex:any) {
			this.usersArray[userIndex].editOption = true
			this.usersArray[userIndex].showSave = false;
			this.selectedUser = this.userData.find((user: any) => user.name == userName);
			this.selectedUser.roles.forEach((role: any, index: any) => {
				if (role.appId == 10001) {
					this.selectedUser.roles[index].roleId = user.selectMonitor;
				} else {
					this.selectedUser.roles[index].roleId = user.selectTraining;
				}
				// organisation.isToggle = false;
				
			});
			if (this.selectedUser._id) {
				await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/users/update`, this.selectedUser).then(() => {
					this.showUserModal = false;
				});
			}
			
		}
	},

	components: {
		spinner,
	},
	async mounted() {
		await this.getAllTenants();
		await this.getAllUsers();
	}
});
</script>