<template>
   <div>
        <div class="tableCornerRadius bg-white">
            <div class="table-container">
                <table>
                <thead>
                    <th scope="col" v-for="column in columnNames" :class="getHeaderProperties('width',column)" :key="column" class="bg-white cursor-pointer capitalize" @click="sort(column)"> 
                        <div class="flex" :class="getHeaderAlignment(column)">            
                            <div>{{ getHeaderProperties('name',column) }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>  
                    </th>
                    <tr v-if="visibility">
                       <td v-for="(column,index) in columnNames" class="bg-white" :class="column == 'status' ? 'flex items-center justify-center' : ''" :key="index">
                        
                        <select v-if="column == 'criticality'" class="widthForSelect filter-font-size " v-model="filters[column]" :class="filters[column] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'">
                            <option :value="filters.column" selected hidden disabled>Select</option>
                            <option class="optionColors" v-for="option in criticalityoptions" :key="option.id" :value="option.id">{{ option.value }}</option>
                        </select>
                            <input v-else type="text" placeholder="Search" class="searchForInputBoxStyling" v-model="filters[column]"/>
                        </td>
                    </tr>
                </thead>
                
                <tbody v-if="loadData == true">
                    <tr class="cursor-pointer empty rowHeight">
                        <td class="relative h-5" :colspan="columnNames.size">
                            <div class="flex items-center justify-center w-full h-16 skeleton">
                                <dots-loader/>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody v-else-if="sortedData.length==0">
                    <tr class="cursor-pointer empty rowHeight">
                        <td class="relative h-10" :colspan="columnNames.size">
                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                        </td>
                    </tr>
                </tbody>

                <tbody class="empty" v-else>
                    <tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight" >
                    
                        <td v-for="column,indexx in columnNames" :key="indexx" @mouseout="doFalse(column)" @click="actionOnClickingTableRow(column,row,index)" class="cursor-pointer relative">
                            <div>
                                <div v-if="column == 'criticality'">
                                    <div class="flex items-center justify-center rounded h-5 w-24 px-2.5  regular" :class="colorCriticalityBg(row[column])">
                                        <div :class="colorCriticalityText(row[column])">
                                            {{
                                                criticalityoptions.find((l) => {
                                                    return l.id == row[column];
                                                }).value
                                            }}
                                        </div>
                                    </div>

                                </div>
                                <div v-else class="table-ellipsis" >
                                    <p class="fullText" @mousemove="toolTip(column, $event, index, row, 'text')">
                                        {{ row[column] }}
                                    </p>
                                </div>
                            </div>
                        </td>

                    </tr>
                </tbody>
               </table>
            </div>
            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
        </div>
  </div>  
</template>
<style scoped>
.lastChild:last-child {
    border: 0px;
}
.fullText{
    white-space: break-spaces;
    font-size: 12px;
}
.table-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widthForSelect{
    width : 100px !important;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
.empty tr td {
  border-bottom: 1px solid #e9e9e9 !important;
}
</style>
<script lang="ts">
import { emitter } from '@/main';
import _ from 'lodash';
import { defineComponent } from 'vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
    props: ['tableRowData','headersData','openPopup','navigate','loadData','tableProps'],
    data(): any {
        return {
            tableHead: [],
            tableRow: [],
            searchTableRow: [],
            visibility: false,
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',

            currentPage: '',
            pageSize: '',
            dynamicPagination:'',

            pop: false,
            styling: { left: '', bottom: '' , top : '' , right : ''},
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            filters: {},
            selectedRowIndex:'',
            pageType:false,
            regionList:[],
            businessEntities:[],
            users:[],
            optionsUserArray:[],
            criticalityoptions: [
                {
                    id:1,
                    value:'Very Low'
                },
                {
                    id:2,
                    value:'Low'
                },
                {
                    id:3,
                    value:'Moderate'
                },
                {
                    id:4,
                    value:'High'
                },
                {
                    id:5,
                    value:'Very High'
                }
            ],
        };
    },
    components: {
        pagination,
        dotsLoader
    },
    async mounted() {

        this.initialize();
        emitter.on('visible', (isBoolean: any) => {
            this.visibility = isBoolean;
			this.visibility == false ? (this.filters = {}) : '';
            
        });
        // emitter.on('*', (type, booleanValue) => {
        // 	if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
        // 		this.showDropDown == true ? (this.showDropDown = false) : '';
        // 	} else {
        // 		if (this.showBlock === true && type != 'deActiviteToolTip' ) {
        // 			this.showDropDown == true ? (this.showDropDown = booleanValue) : '';
        // 		} else if (this.showBlock === false) {
        // 			this.showDropDown == true ? '' : (this.showDropDown = booleanValue);
        // 			this.showBlock = true;
        // 		}
        // 	}
        // });
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
        }),
        sortedData: function (): any {
            return [...this.tableRow]
                .filter((row: any) => {
                    for (const key in this.filters) {
                        const inputValue = this.filters[key];
                        if (row[key] == '') {
                                return false;
                            }
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (String(row[key]).toLowerCase().includes(String(inputValue).toLowerCase()) === false) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != '_id') names.add(key);
            }
            return names;
        }
    },
    watch: {
       visibility(newValue) {
			if (newValue == false) {
				this.initialize();
			}
		},
		tableRowData: {
			handler(newVal, oldVal) {
				this.tableRow = this.tableRowData;
				this.initialize();
			},
			deep: true
		}
    },
    methods: {
        initialize() {
            this.tableRow = this.tableRowData;
            this.searchTableRow = this.tableRowData;
        },
        paginationUpdations(currentPage:any,pageSize:any){
            this.currentPage=currentPage;
            this.pageSize=pageSize;
        },
        sort(tableHead: any) {
            this.className = tableHead;
            if (tableHead === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = tableHead;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            
            this.tipText = row[column];
			this.tipColumn = column;
			this.tipIndex = index;

			switch (status) {
				case 'text':
					this.handleTextTooltip(e,column);
					break;
            }
		},
        handleTextTooltip(e:any, column :any) {
            const container = e.currentTarget.closest('.table-ellipsis');
           if (container && this.isTextOverflowed(container)) {
              let obj:any = {
                tipText : this.tipText
              }

              emitter.emit('activiteToolTip',obj)
                
            } 
        },
        isTextOverflowed(element:any) {
            return element.scrollHeight > element.clientHeight;
        },
        actionOnClickingTableRow(column:any,obj:any,index:any){
            emitter.emit('dataTransfering', obj);
            console.log("cameobject4334")
        },
        doFalse(column:any) {
             emitter.emit('deActiviteToolTip')
        },
        getHeaderAlignment(column:any){
            if(column == 'criticality'){
                return 'pl-3 w-28'
            } else if(column == 'departments'){
                return 'w-28'
            } else if(column == 'description'){
                return 'w-52'
            } else if(column == 'name'){
                return 'w-36'
            }
        },
        colorCriticalityText(value: any) {
            if (value == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 2) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 4) {
                return ['text-error font-semibold'];
            } else if (value == 5) {
                return ['text-darkred font-semibold'];
            }
        },
        colorCriticalityBg(value: any) {
            if (value == 1) {
                return ['bg-veryLowColor border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['bg-lowColor border-solid border border-green'];
            } else if (value == 3) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (value == 4) {
                return ['bg-highColor border-solid border border-error'];
            } else if (value == 5) {
                return ['bg-veryHighColor border-solid border border-darkred'];
            }
        },
        getHeaderProperties(prop:any,columnName:any){
            if(prop=='name'){
                let a = this.tableProps.headerNames.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return columnName
                }

            }else if(prop=='width'){
                let a = this.tableProps.headerWidth.find((obj:any)=>{return obj.key == columnName});
                if(a!= undefined){
                    return a.value
                }else{
                    return ''
                }
            }
        }
    },
});
</script>

