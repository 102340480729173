<template>
    <div class="pt-3 pl-5 pr-5 overflow-y-hidden">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Clients Information</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex items-center" @click="filterOpen" :class="tenentsArray.length == 0?'btndisabled pointer-events-none':'filterButton h-8'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                    <div class="text-center">
                      <button class="btnprimary mr-2" @click="addNewClient()">Add Client</button>
                   </div>
                </div>
            </div>
        </div>
        <div class="my-3 text-xs">
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
        </div>
        
        <div >
            <newTable :tableRowData="tenentsArray" :headersData="headers" :tableProps="tableProps" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>

            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
        </div>
    </div>
</template>

<style>

</style>

<script lang="ts">
import { defineComponent } from 'vue';
import newTable from '@/components/newTable.vue';
import popup from '@/components/sidePopup.vue';
import toolTip from '@/components/toolTip.vue';
import { emitter, toast } from '@/main';
import { required } from '@vuelidate/validators';
import { helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
const restrictCharacters = helpers.regex(/^.{0,20}$/);

export default defineComponent({
    data() :any{
        return {
            v$:useVuelidate(),
            show:false,
            clickOnButton:false,
            tenentsArray:[],
            headers:['Organisation','Org Key', 'Display Name', 'Contact Person', 'mail Id'],
            dataLoading:true,
            toolTipText:'',
            toolTipActive:false,
            allTenants:[],
            showFilters:false,
            tableProps:{
                headerNames:[{key:'Organisation',value:'Organisations'}, {key:'Org Key',value:'Org Key'}, {key:'Display Name',value:'Display Name'}, {key:'mail Id',value:'Mail Id'},{key:'Contact Person',value:'Contact Person'}],
                
                headerWidth:[{key:'Organisation',value:'w-1/5'}, {key:'Org Key',value:'w-1/5'}, {key:'Display Name',value:'w-1/5'}, {key:'mail Id',value:'w-1/5'},{key:'Contact Person',value:'w-1/5'}],
            }
        }
    },
    components:{
        toolTip,
        newTable
    },
    methods:{
		addNewClient(){
			this.$router.push({ name: 'start-onBoard' })
		},
       	filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.tenentsArray;
            } else {
                this.tenentsArray = this.dummyList;
            }
            emitter.emit('visible',this.showFilters)
        },
		redirectionMethod(data: any){
			console.log("dayattttaaa", data);
			if(this.$route.path.includes('/organisations')){
                this.$router.push({ name: 'edit-organisation', params: { organisationId: data._id } });
            }
		},
        getAllTenants() {
			this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.allTenants = res.data;
				console.log("allTenants", this.allTenants)
				this.allTenants.map((tenent: any)=>{
					if(tenent._id){
						let obj = {
							_id: tenent._id,
							'mail Id' : tenent?.contactPersonEmail || tenent?.primaryContactEmail || 'N/A',
							'Contact Person': tenent?.contactPerson || tenent?.primaryContactName || 'N/A',
							'Organisation': tenent?.organizationName || 'N/A',
							'Org Key': tenent?.orgKey || tenent?.organizationKey || 'N/A',
							'Display Name': tenent?.displayName || 'N/A'
						}
						this.tenentsArray.push(obj);
					}
				})
                this.dataLoading = false;
				// this.apps = this.allTenants[0].apps;
			});
		},
    },
    async created(){
        this.dataLoading = true;
        await this.getAllTenants();
    },
    async mounted(){
        // this.dataLoading = false;

		emitter.off('dataTransfering');
        emitter.on('dataTransfering', (isData: any) => {
            this.redirectionMethod(isData)
        })
        
        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    }
})
</script>
