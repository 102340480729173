<template>
    <div class="pt-3 pl-5 pr-5 overflow-y-hidden">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Teams</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex items-center" @click="filterOpen" :class="teamsArray.length == 0?'btndisabled pointer-events-none':'filterButton h-8'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                    <div class="text-center">
                      <button class="btnprimary mr-2" @click="showPopUp()">Add</button>
                   </div>
                </div>
            </div>
        </div>
        <div class="my-3 text-xs">
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
        </div>
        
        <div>
            <newTable :tableRowData="teamsArray" :headersData="headers" :tableProps="tableProps" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>

            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
        </div>
        <popup v-if="showAddPopup" :popUp="showAddPopup">
			<template v-slot:header>
				<div class="flex items-center justify-between m-4 rounded">
					<h1>Team</h1>
					<button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
						<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
					</button>
				</div>
			</template>
			<fieldset>
				<div class="popup-business">
                    <div>
                        <div>
                            <div class="popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row upperspacing w-full gap-3">
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Team Name</label>
                                            <input class="inputboxstyling mt-2" placeholder="Team Name"  v-model.trim="teamObj.teamName"  @input="v$.teamObj.teamName.$touch()" :class="{'is-invalid': v$.teamObj.teamName.$error ||checkteamName}" />
                                            <div v-if="v$.teamObj.teamName.$error " class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.teamObj.teamName.required.$invalid ">Required</div>
                                                <div v-else-if="v$.teamObj.teamName.required && v$.teamObj.teamName.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                            </div>
                                            <div v-else-if="checkteamName" class="text-red-500">
                                                <div class="error-text text-xs">Team Name Already exists</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Display Name</label>
                                            <input class="inputboxstyling mt-2" placeholder="Display Name" @input="v$.teamObj.teamDisplayName.$touch()"  v-model.trim="teamObj.teamDisplayName"  :class="{'is-invalid': v$.teamObj.teamDisplayName.$error||checkteamDisplayName }"/>
											
                                            <div v-if="v$.teamObj.teamDisplayName.$error " class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.teamObj.teamDisplayName.required.$invalid ">Required</div>
                                                <div v-else-if="v$.teamObj.teamDisplayName.required && v$.teamObj.teamDisplayName.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                            </div>
                                            <div v-else-if="checkteamDisplayName" class="text-red-500">
                                                <div class="error-text text-xs">Display Name Already exists</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div v-if="editPopup == false" class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                                    <button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(dummyteamObj) == JSON.stringify(teamObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Reset</button>
                                    <button class="" :disabled="clickOnButton" :class="(JSON.stringify(dummyteamObj) == JSON.stringify(teamObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickSaveBtn(teamObj)">Save</button>
                                </div>
                                <div v-else class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                                    <button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(dummyteamObj) == JSON.stringify(teamObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Cancel</button>
                                    <button class="" :disabled="clickOnButton" :class="(JSON.stringify(dummyteamObj) == JSON.stringify(teamObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickUpdateBtn(teamObj)">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</fieldset>
		</popup>
    </div>
</template>

<style>

</style>

<script lang="ts">
import { defineComponent } from 'vue';
import newTable from '@/components/newTable.vue';
import popup from '@/components/sidePopup.vue';
import toolTip from '@/components/toolTip.vue';
import { emitter, toast } from '@/main';
import { required } from '@vuelidate/validators';
import { helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
const restrictCharacters = helpers.regex(/^.{0,20}$/);

export default defineComponent({
    data() :any{
        return {
            v$:useVuelidate(),
            show:false,
            clickOnButton:false,
            showAddPopup:false,
            teamsArray:[],
            headers:['teamName','teamDisplayName'],
            dataLoading:true,
            toolTipText:'',
            toolTipActive:false,
            allteamNames:[],
            allteamDisplayNames:[],
            alleditteamNames:[],
            alleditTeamDisplayNames:[],
            dummyList:[],
            editPopup: false,
            dummyteamObj:{},
            showFilters:false,
            teamObj: {
                teamName:'',
                teamDisplayName:'',
            },
            tableProps:{
                headerNames:[{key:'teamName',value:'Name'},{key:'teamDisplayName',value:'Display Name'}],
                
                headerWidth:[{key:'teamName',value:'w-1/5'},{key:'teamDisplayName',value:'w-1/5'}],
            }
        }
    },
    components:{
        toolTip,
        popup,
        newTable
    },
    validations() {
        return {
            teamObj:{
                teamName:{ required,restrictCharacters },
                teamDisplayName:{ required ,restrictCharacters}
            }
        }
    },
    computed:{
        
        checkteamName: function (): any {
        
             const trimmedTeamName = this.teamObj.teamName.trim().replace(/\s+/g, '').toLowerCase();
             
             if(this.allteamNames == null || this.allteamNames == undefined || this.allteamNames.length == 0){
                return false
             }
            if(this.editPopup == false){
               let a = false;
               a = this.allteamNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedTeamName);
                return a
            }else if(this.editPopup == true){
                let a = false;
                 a = this.alleditteamNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedTeamName);
                return a
            }
        },
        checkteamDisplayName: function (): any {
             const trimmedTeamDisplayName = this.teamObj.teamDisplayName.trim().replace(/\s+/g, '').toLowerCase();
             if(this.allteamDisplayNames == null || this.allteamDisplayNames == undefined || this.allteamDisplayNames.length == 0){
                return false
             }
            if(this.editPopup === false){
               let a = false;
               a = this.allteamDisplayNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedTeamDisplayName);
                return a
            }else if(this.editPopup == true){
                let a = false;
                 a = this.alleditTeamDisplayNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedTeamDisplayName);
                return a
            }
        }
    },
    methods:{
       filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.teamsArray;
            } else {
                this.teamsArray = this.dummyList;
            }
            emitter.emit('visible',this.showFilters)
        },
        onCancelButtonAddPopUp(){
            this.v$.$reset();        
            this.showAddPopup = false
            this.teamObj.teamName = ''
            this.teamObj.teamDisplayName = ''
        },
        onCancelbtn(){
            this.v$.$reset();
            if(this.editPopup == false){
                this.teamObj.teamName = ''
                this.teamObj.teamDisplayName = ''
            } else {
                this.teamObj = {...this.dummyteamObj}
            }
        },
        async getAllTeams(){
            this.teamsArray=[]
            this.allteamNames=[]
            this.allteamDisplayNames=[]
            await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/teams/getall`).then((res: any) => {
				let teamData:any = res.data
                teamData.map((team:any)=>{
                    this.teamsArray.push({_id: team._id,teamName: team.name,teamDisplayName: team.displayName})
                    this.allteamNames.push(team.name),
                    this.allteamDisplayNames.push(team.displayName)
                })

			})
        },
        async onClickUpdateBtn(obj:any){
            console.log("kjdfgh",this.v$.teamObj.$invalid)
            this.v$.$touch();
            if(!this.v$.teamObj.$invalid&&this.checkteamName==false&&this.checkteamDisplayName==false){
                this.clickOnButton = true
                console.log("kjdfgh",obj)
            //     // endpoint:/root/team/:id/update

            //     // payload:
            //     //     [ { "name":"HR","displayName":"hr"}]
            let id:any = obj._id
                const payload = {name:obj.teamName,displayName:obj.teamDisplayName}
            try {
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/team/${id}/update`,payload).then((res: any) => {
                    console.log("fsdhjg",payload)
                    toast.info('Updated Sucessfully', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                this.showAddPopup = false
                await this.getAllTeams();
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally{
                this.clickOnButton = false
            }
                this.teamObj.teamName = '',
                this.teamObj.teamDisplayName = ''
                this.v$.$reset();

            }
        },
        async onClickSaveBtn(obj: any){
            this.v$.$touch();
            if(!this.v$.teamObj.$invalid&&this.checkteamName==false&&this.checkteamDisplayName==false){
                this.clickOnButton = true
                // endpoint:/root/teams/create

                // payload:
                //     [ { "name":"HR","displayName":"hr"}]
                const payload = [{name:obj.teamName,displayName:obj.teamDisplayName}]
            try {
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/teams/create`,payload).then((res: any) => {
                    console.log("fsdhjg",payload)
                    toast.info('Updated Sucessfully', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                this.showAddPopup = false
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally{
                this.clickOnButton = false
            }
            this.v$.$reset();
                await this.getAllTeams();
                this.teamObj.teamName = '',
                this.teamObj.teamDisplayName = ''

            }
        },
        showPopUp(){
            this.editPopup = false
            this.showAddPopup = true
            this.dummyteamObj = {...this.teamObj}
        },
        showEditPopup(data:any){
            this.teamObj = {...data};
            this.dummyteamObj = {...this.teamObj}
            this.showAddPopup = true
            this.editPopup = true
        }
    },
    async mounted(){
        this.dataLoading = true;
        await this.getAllTeams();
        this.dataLoading = false;

        emitter.off('dataTransfering');
        emitter.on('dataTransfering', (isData: any) => {
            console.log('cameobject',isData)
            this.alleditteamNames=[]
            this.alleditTeamDisplayNames=[]
            this.showEditPopup(isData)
            this.teamsArray.forEach((team: any) => {
                if(isData._id != team._id){
                    this.alleditteamNames.push(team.teamName)
                    this.alleditTeamDisplayNames.push(team.teamDisplayName)
                }
            });
        })
        
        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    }
})
</script>
