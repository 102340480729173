<template>
    <div class="fixed h-full bg-primary sidenav flex flex-col justify-between z-50" :class="{ 'w-40': isSidebarToggle, 'w-10': !isSidebarToggle }">
        <ul>
            <li @click="navigate('organisations', 'organisations')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'organisations' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'" @mouseenter="sidebar.organisations = true" @mouseleave="sidebar.organisations = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.50911 3.97988C2.78959 4.15823 2.17461 4.78243 2.00241 5.50195C1.94399 5.75409 1.94399 6.25837 2.00241 6.51052C2.17461 7.23004 2.80188 7.86347 3.50911 8.02951C3.6567 8.06641 3.67823 8.07871 3.61673 8.09101C3.02328 8.2263 2.84186 8.2878 2.54359 8.45385C1.85174 8.84743 1.34439 9.47778 1.10455 10.2557L1.02152 10.5263L1.00922 11.8393L1 13.1553H2.98023H4.96046L4.98198 12.8848C5.03733 12.1929 5.25872 11.5595 5.64923 10.9845C5.8491 10.6862 6.25806 10.2619 6.53787 10.0559C6.66394 9.96054 6.77157 9.88367 6.77772 9.87752C6.79924 9.86214 6.61475 9.53313 6.48253 9.34249C6.21194 8.9612 5.9229 8.69984 5.50779 8.45999C5.27102 8.32162 4.84976 8.16173 4.63144 8.13098C4.37008 8.08793 4.35778 8.07563 4.55457 8.02951C5.26487 7.86654 5.89215 7.23004 6.06434 6.49514C6.13506 6.1938 6.11047 5.6188 6.01822 5.34513C5.80913 4.73323 5.30484 4.22895 4.69294 4.01986C4.43158 3.93068 3.792 3.90916 3.50911 3.97988Z" fill="#E9E9E9"/>
                            <path d="M13.1953 3.97988C12.7741 4.08443 12.3221 4.38577 12.0607 4.73015C11.7686 5.12374 11.6425 5.50195 11.6425 6.00623C11.6425 6.51052 11.7686 6.88873 12.0607 7.28231C12.3251 7.62978 12.7864 7.93726 13.1953 8.02951C13.3921 8.07563 13.3798 8.08793 13.1185 8.13098C12.9001 8.16173 12.4789 8.32162 12.2421 8.45999C11.827 8.69984 11.538 8.9612 11.2674 9.34249C11.1352 9.53313 10.9507 9.86214 10.9722 9.87752C10.9783 9.88367 11.086 9.96054 11.212 10.0559C11.4918 10.2619 11.9008 10.6862 12.1007 10.9845C12.4912 11.5595 12.7126 12.1929 12.7679 12.8848L12.7894 13.1553H14.7697H16.7499L16.7407 11.8393L16.7284 10.5263L16.6454 10.2557C16.4055 9.47778 15.8982 8.84743 15.2063 8.45385C14.908 8.2878 14.7266 8.2263 14.1332 8.09101C14.0717 8.07871 14.0932 8.06641 14.2408 8.02951C14.9572 7.86347 15.5784 7.23004 15.7506 6.49514C15.8213 6.1938 15.7967 5.6188 15.7044 5.34513C15.4953 4.73323 14.9911 4.22895 14.3792 4.01986C14.1178 3.93068 13.4782 3.90916 13.1953 3.97988Z" fill="#E9E9E9"/>
                            <path d="M8.42898 6.03979C8.15839 6.09821 7.77403 6.28271 7.54649 6.4672C7.09141 6.83311 6.78392 7.48499 6.78392 8.08152C6.78392 8.67804 7.09141 9.32992 7.54649 9.69583C7.76173 9.86803 8.15224 10.0617 8.39516 10.114L8.57658 10.154L8.34904 10.1909C7.2882 10.3723 6.39033 11.1349 6.03057 12.165C5.88605 12.5801 5.86145 12.8322 5.86145 13.9576V15H8.87484H11.8882V13.9576C11.8882 12.8322 11.8636 12.5801 11.7191 12.165C11.3594 11.1349 10.4615 10.3723 9.40065 10.1909L9.17311 10.154L9.35452 10.114C9.59744 10.0617 9.98795 9.86803 10.2032 9.69583C10.6583 9.32992 10.9658 8.67804 10.9658 8.08152C10.9658 7.64796 10.7997 7.16212 10.523 6.79929C10.0556 6.18123 9.21308 5.87682 8.42898 6.03979Z" fill="#E9E9E9"/>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName == 'organisations' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Organisations</div>
                    </a>
                </div>
                <div v-if="sidebar.organisations == true && !isSidebarToggle" class="absolute z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'organisations' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Organisations</div>
                </div>
            </li>
			<li @click="navigate('teams', 'teams')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'teams' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'" @mouseenter="sidebar.teams = true" @mouseleave="sidebar.teams = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2328_26181)">
                                <path
                                    d="M5.02793 2.80666L1.13449 4.48927L1.12968 5.06084L1.12646 5.63402H1.67074H2.21342L2.22305 5.70627C2.24553 5.87003 2.37879 6.04985 2.53613 6.12692L2.62765 6.17188H8.96954H15.3114L15.3869 6.13655C15.5539 6.05949 15.6968 5.87646 15.716 5.71269L15.7257 5.63402H16.2539H16.7805L16.7773 5.06245L16.7725 4.49087L12.8646 2.80666C10.7164 1.88026 8.94867 1.12245 8.93903 1.12405C8.9294 1.12405 7.16972 1.88187 5.02793 2.80666Z"
                                    fill="#E9E9E9"
                                />
                                <path
                                    d="M3.19769 10.1138V13.4822L2.89746 13.4902C2.61006 13.4967 2.5908 13.4999 2.49768 13.5416C2.37887 13.5946 2.25042 13.7198 2.19905 13.8322C2.16533 13.9061 2.16212 13.9382 2.1573 14.2689L2.15088 14.627H8.95998H15.7691V14.2818C15.7691 13.9767 15.7659 13.9285 15.737 13.8515C15.6904 13.7262 15.57 13.5994 15.4448 13.5432C15.3484 13.4999 15.3308 13.4967 15.0434 13.4902L14.7415 13.4822V10.1138V6.74374H13.6899H12.6383V10.1154V13.487H12.1245H11.6107V10.1154V6.74374H10.5591H9.50747V10.1154V13.487H8.9937H8.47992V10.1154V6.74374H7.42829H6.37667V10.1154V13.487H5.86289H5.34912V10.1154V6.74374H4.27341H3.19769V10.1138Z"
                                    fill="#E9E9E9"
                                />
                                <path
                                    d="M1.51675 15.2197C1.36262 15.2727 1.24542 15.3754 1.17156 15.5263C1.13463 15.597 1.13463 15.6114 1.12982 16.236L1.125 16.875H8.95362H16.7838L16.7774 16.252L16.7726 15.6307L16.7277 15.5328C16.6731 15.414 16.5462 15.2855 16.4354 15.2341L16.3552 15.1972L8.97771 15.194C1.91172 15.1908 1.59703 15.1908 1.51675 15.2197Z"
                                    fill="#E9E9E9"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2328_26181">
                                    <rect width="18" height="18" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName == 'teams' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Teams</div>
                    </a>
                </div>
                <div v-if="sidebar.teams == true && !isSidebarToggle" class="absolute z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'teams' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Teams</div>
                </div>
            </li>
            <li @click="navigate('activities', 'activities')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'activities' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'" @mouseenter="sidebar.activities = true" @mouseleave="sidebar.activities = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.50911 3.97988C2.78959 4.15823 2.17461 4.78243 2.00241 5.50195C1.94399 5.75409 1.94399 6.25837 2.00241 6.51052C2.17461 7.23004 2.80188 7.86347 3.50911 8.02951C3.6567 8.06641 3.67823 8.07871 3.61673 8.09101C3.02328 8.2263 2.84186 8.2878 2.54359 8.45385C1.85174 8.84743 1.34439 9.47778 1.10455 10.2557L1.02152 10.5263L1.00922 11.8393L1 13.1553H2.98023H4.96046L4.98198 12.8848C5.03733 12.1929 5.25872 11.5595 5.64923 10.9845C5.8491 10.6862 6.25806 10.2619 6.53787 10.0559C6.66394 9.96054 6.77157 9.88367 6.77772 9.87752C6.79924 9.86214 6.61475 9.53313 6.48253 9.34249C6.21194 8.9612 5.9229 8.69984 5.50779 8.45999C5.27102 8.32162 4.84976 8.16173 4.63144 8.13098C4.37008 8.08793 4.35778 8.07563 4.55457 8.02951C5.26487 7.86654 5.89215 7.23004 6.06434 6.49514C6.13506 6.1938 6.11047 5.6188 6.01822 5.34513C5.80913 4.73323 5.30484 4.22895 4.69294 4.01986C4.43158 3.93068 3.792 3.90916 3.50911 3.97988Z" fill="#E9E9E9"/>
                            <path d="M13.1953 3.97988C12.7741 4.08443 12.3221 4.38577 12.0607 4.73015C11.7686 5.12374 11.6425 5.50195 11.6425 6.00623C11.6425 6.51052 11.7686 6.88873 12.0607 7.28231C12.3251 7.62978 12.7864 7.93726 13.1953 8.02951C13.3921 8.07563 13.3798 8.08793 13.1185 8.13098C12.9001 8.16173 12.4789 8.32162 12.2421 8.45999C11.827 8.69984 11.538 8.9612 11.2674 9.34249C11.1352 9.53313 10.9507 9.86214 10.9722 9.87752C10.9783 9.88367 11.086 9.96054 11.212 10.0559C11.4918 10.2619 11.9008 10.6862 12.1007 10.9845C12.4912 11.5595 12.7126 12.1929 12.7679 12.8848L12.7894 13.1553H14.7697H16.7499L16.7407 11.8393L16.7284 10.5263L16.6454 10.2557C16.4055 9.47778 15.8982 8.84743 15.2063 8.45385C14.908 8.2878 14.7266 8.2263 14.1332 8.09101C14.0717 8.07871 14.0932 8.06641 14.2408 8.02951C14.9572 7.86347 15.5784 7.23004 15.7506 6.49514C15.8213 6.1938 15.7967 5.6188 15.7044 5.34513C15.4953 4.73323 14.9911 4.22895 14.3792 4.01986C14.1178 3.93068 13.4782 3.90916 13.1953 3.97988Z" fill="#E9E9E9"/>
                            <path d="M8.42898 6.03979C8.15839 6.09821 7.77403 6.28271 7.54649 6.4672C7.09141 6.83311 6.78392 7.48499 6.78392 8.08152C6.78392 8.67804 7.09141 9.32992 7.54649 9.69583C7.76173 9.86803 8.15224 10.0617 8.39516 10.114L8.57658 10.154L8.34904 10.1909C7.2882 10.3723 6.39033 11.1349 6.03057 12.165C5.88605 12.5801 5.86145 12.8322 5.86145 13.9576V15H8.87484H11.8882V13.9576C11.8882 12.8322 11.8636 12.5801 11.7191 12.165C11.3594 11.1349 10.4615 10.3723 9.40065 10.1909L9.17311 10.154L9.35452 10.114C9.59744 10.0617 9.98795 9.86803 10.2032 9.69583C10.6583 9.32992 10.9658 8.67804 10.9658 8.08152C10.9658 7.64796 10.7997 7.16212 10.523 6.79929C10.0556 6.18123 9.21308 5.87682 8.42898 6.03979Z" fill="#E9E9E9"/>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName == 'activities' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Activities</div>
                    </a>
                </div>
                <div v-if="sidebar.activities == true && !isSidebarToggle" class="absolute z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'activities' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Activities</div>
                </div>
            </li>
            <li @click="navigate('vendors', 'vendors')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'vendors' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'" @mouseenter="sidebar.vendors = true" @mouseleave="sidebar.vendors = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.50911 3.97988C2.78959 4.15823 2.17461 4.78243 2.00241 5.50195C1.94399 5.75409 1.94399 6.25837 2.00241 6.51052C2.17461 7.23004 2.80188 7.86347 3.50911 8.02951C3.6567 8.06641 3.67823 8.07871 3.61673 8.09101C3.02328 8.2263 2.84186 8.2878 2.54359 8.45385C1.85174 8.84743 1.34439 9.47778 1.10455 10.2557L1.02152 10.5263L1.00922 11.8393L1 13.1553H2.98023H4.96046L4.98198 12.8848C5.03733 12.1929 5.25872 11.5595 5.64923 10.9845C5.8491 10.6862 6.25806 10.2619 6.53787 10.0559C6.66394 9.96054 6.77157 9.88367 6.77772 9.87752C6.79924 9.86214 6.61475 9.53313 6.48253 9.34249C6.21194 8.9612 5.9229 8.69984 5.50779 8.45999C5.27102 8.32162 4.84976 8.16173 4.63144 8.13098C4.37008 8.08793 4.35778 8.07563 4.55457 8.02951C5.26487 7.86654 5.89215 7.23004 6.06434 6.49514C6.13506 6.1938 6.11047 5.6188 6.01822 5.34513C5.80913 4.73323 5.30484 4.22895 4.69294 4.01986C4.43158 3.93068 3.792 3.90916 3.50911 3.97988Z" fill="#E9E9E9"/>
                            <path d="M13.1953 3.97988C12.7741 4.08443 12.3221 4.38577 12.0607 4.73015C11.7686 5.12374 11.6425 5.50195 11.6425 6.00623C11.6425 6.51052 11.7686 6.88873 12.0607 7.28231C12.3251 7.62978 12.7864 7.93726 13.1953 8.02951C13.3921 8.07563 13.3798 8.08793 13.1185 8.13098C12.9001 8.16173 12.4789 8.32162 12.2421 8.45999C11.827 8.69984 11.538 8.9612 11.2674 9.34249C11.1352 9.53313 10.9507 9.86214 10.9722 9.87752C10.9783 9.88367 11.086 9.96054 11.212 10.0559C11.4918 10.2619 11.9008 10.6862 12.1007 10.9845C12.4912 11.5595 12.7126 12.1929 12.7679 12.8848L12.7894 13.1553H14.7697H16.7499L16.7407 11.8393L16.7284 10.5263L16.6454 10.2557C16.4055 9.47778 15.8982 8.84743 15.2063 8.45385C14.908 8.2878 14.7266 8.2263 14.1332 8.09101C14.0717 8.07871 14.0932 8.06641 14.2408 8.02951C14.9572 7.86347 15.5784 7.23004 15.7506 6.49514C15.8213 6.1938 15.7967 5.6188 15.7044 5.34513C15.4953 4.73323 14.9911 4.22895 14.3792 4.01986C14.1178 3.93068 13.4782 3.90916 13.1953 3.97988Z" fill="#E9E9E9"/>
                            <path d="M8.42898 6.03979C8.15839 6.09821 7.77403 6.28271 7.54649 6.4672C7.09141 6.83311 6.78392 7.48499 6.78392 8.08152C6.78392 8.67804 7.09141 9.32992 7.54649 9.69583C7.76173 9.86803 8.15224 10.0617 8.39516 10.114L8.57658 10.154L8.34904 10.1909C7.2882 10.3723 6.39033 11.1349 6.03057 12.165C5.88605 12.5801 5.86145 12.8322 5.86145 13.9576V15H8.87484H11.8882V13.9576C11.8882 12.8322 11.8636 12.5801 11.7191 12.165C11.3594 11.1349 10.4615 10.3723 9.40065 10.1909L9.17311 10.154L9.35452 10.114C9.59744 10.0617 9.98795 9.86803 10.2032 9.69583C10.6583 9.32992 10.9658 8.67804 10.9658 8.08152C10.9658 7.64796 10.7997 7.16212 10.523 6.79929C10.0556 6.18123 9.21308 5.87682 8.42898 6.03979Z" fill="#E9E9E9"/>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName == 'vendors' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Vendors</div>
                    </a>
                </div>
                <div v-if="sidebar.vendors == true && !isSidebarToggle" class="absolute z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'vendors' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Vendors</div>
                </div>
            </li>
            <li @click="navigate('systems', 'systems')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'systems' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'" @mouseenter="sidebar.systems = true" @mouseleave="sidebar.systems = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.50911 3.97988C2.78959 4.15823 2.17461 4.78243 2.00241 5.50195C1.94399 5.75409 1.94399 6.25837 2.00241 6.51052C2.17461 7.23004 2.80188 7.86347 3.50911 8.02951C3.6567 8.06641 3.67823 8.07871 3.61673 8.09101C3.02328 8.2263 2.84186 8.2878 2.54359 8.45385C1.85174 8.84743 1.34439 9.47778 1.10455 10.2557L1.02152 10.5263L1.00922 11.8393L1 13.1553H2.98023H4.96046L4.98198 12.8848C5.03733 12.1929 5.25872 11.5595 5.64923 10.9845C5.8491 10.6862 6.25806 10.2619 6.53787 10.0559C6.66394 9.96054 6.77157 9.88367 6.77772 9.87752C6.79924 9.86214 6.61475 9.53313 6.48253 9.34249C6.21194 8.9612 5.9229 8.69984 5.50779 8.45999C5.27102 8.32162 4.84976 8.16173 4.63144 8.13098C4.37008 8.08793 4.35778 8.07563 4.55457 8.02951C5.26487 7.86654 5.89215 7.23004 6.06434 6.49514C6.13506 6.1938 6.11047 5.6188 6.01822 5.34513C5.80913 4.73323 5.30484 4.22895 4.69294 4.01986C4.43158 3.93068 3.792 3.90916 3.50911 3.97988Z" fill="#E9E9E9"/>
                            <path d="M13.1953 3.97988C12.7741 4.08443 12.3221 4.38577 12.0607 4.73015C11.7686 5.12374 11.6425 5.50195 11.6425 6.00623C11.6425 6.51052 11.7686 6.88873 12.0607 7.28231C12.3251 7.62978 12.7864 7.93726 13.1953 8.02951C13.3921 8.07563 13.3798 8.08793 13.1185 8.13098C12.9001 8.16173 12.4789 8.32162 12.2421 8.45999C11.827 8.69984 11.538 8.9612 11.2674 9.34249C11.1352 9.53313 10.9507 9.86214 10.9722 9.87752C10.9783 9.88367 11.086 9.96054 11.212 10.0559C11.4918 10.2619 11.9008 10.6862 12.1007 10.9845C12.4912 11.5595 12.7126 12.1929 12.7679 12.8848L12.7894 13.1553H14.7697H16.7499L16.7407 11.8393L16.7284 10.5263L16.6454 10.2557C16.4055 9.47778 15.8982 8.84743 15.2063 8.45385C14.908 8.2878 14.7266 8.2263 14.1332 8.09101C14.0717 8.07871 14.0932 8.06641 14.2408 8.02951C14.9572 7.86347 15.5784 7.23004 15.7506 6.49514C15.8213 6.1938 15.7967 5.6188 15.7044 5.34513C15.4953 4.73323 14.9911 4.22895 14.3792 4.01986C14.1178 3.93068 13.4782 3.90916 13.1953 3.97988Z" fill="#E9E9E9"/>
                            <path d="M8.42898 6.03979C8.15839 6.09821 7.77403 6.28271 7.54649 6.4672C7.09141 6.83311 6.78392 7.48499 6.78392 8.08152C6.78392 8.67804 7.09141 9.32992 7.54649 9.69583C7.76173 9.86803 8.15224 10.0617 8.39516 10.114L8.57658 10.154L8.34904 10.1909C7.2882 10.3723 6.39033 11.1349 6.03057 12.165C5.88605 12.5801 5.86145 12.8322 5.86145 13.9576V15H8.87484H11.8882V13.9576C11.8882 12.8322 11.8636 12.5801 11.7191 12.165C11.3594 11.1349 10.4615 10.3723 9.40065 10.1909L9.17311 10.154L9.35452 10.114C9.59744 10.0617 9.98795 9.86803 10.2032 9.69583C10.6583 9.32992 10.9658 8.67804 10.9658 8.08152C10.9658 7.64796 10.7997 7.16212 10.523 6.79929C10.0556 6.18123 9.21308 5.87682 8.42898 6.03979Z" fill="#E9E9E9"/>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName == 'systems' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Systems</div>
                    </a>
                </div>
                <div v-if="sidebar.systems == true && !isSidebarToggle" class="absolute z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'systems' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Systems</div>
                </div>
            </li>
            <li @click="navigate('frameworks', 'frameworks')" class="cursor-pointer hover:bg-white svg-icon pl-2.5 pr-2.5" :class="selectedOptionName === 'frameworks' ? 'bg-white text-black font-bold a svg-icon123' : 'text-white font-normal'" @mouseenter="sidebar.frameworks = true" @mouseleave="sidebar.frameworks = false">
                <div>
                    <a class="flex navchild items-center hover:text-black py-1.5">
                        <div class="py-0.5">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.50911 3.97988C2.78959 4.15823 2.17461 4.78243 2.00241 5.50195C1.94399 5.75409 1.94399 6.25837 2.00241 6.51052C2.17461 7.23004 2.80188 7.86347 3.50911 8.02951C3.6567 8.06641 3.67823 8.07871 3.61673 8.09101C3.02328 8.2263 2.84186 8.2878 2.54359 8.45385C1.85174 8.84743 1.34439 9.47778 1.10455 10.2557L1.02152 10.5263L1.00922 11.8393L1 13.1553H2.98023H4.96046L4.98198 12.8848C5.03733 12.1929 5.25872 11.5595 5.64923 10.9845C5.8491 10.6862 6.25806 10.2619 6.53787 10.0559C6.66394 9.96054 6.77157 9.88367 6.77772 9.87752C6.79924 9.86214 6.61475 9.53313 6.48253 9.34249C6.21194 8.9612 5.9229 8.69984 5.50779 8.45999C5.27102 8.32162 4.84976 8.16173 4.63144 8.13098C4.37008 8.08793 4.35778 8.07563 4.55457 8.02951C5.26487 7.86654 5.89215 7.23004 6.06434 6.49514C6.13506 6.1938 6.11047 5.6188 6.01822 5.34513C5.80913 4.73323 5.30484 4.22895 4.69294 4.01986C4.43158 3.93068 3.792 3.90916 3.50911 3.97988Z" fill="#E9E9E9"/>
                            <path d="M13.1953 3.97988C12.7741 4.08443 12.3221 4.38577 12.0607 4.73015C11.7686 5.12374 11.6425 5.50195 11.6425 6.00623C11.6425 6.51052 11.7686 6.88873 12.0607 7.28231C12.3251 7.62978 12.7864 7.93726 13.1953 8.02951C13.3921 8.07563 13.3798 8.08793 13.1185 8.13098C12.9001 8.16173 12.4789 8.32162 12.2421 8.45999C11.827 8.69984 11.538 8.9612 11.2674 9.34249C11.1352 9.53313 10.9507 9.86214 10.9722 9.87752C10.9783 9.88367 11.086 9.96054 11.212 10.0559C11.4918 10.2619 11.9008 10.6862 12.1007 10.9845C12.4912 11.5595 12.7126 12.1929 12.7679 12.8848L12.7894 13.1553H14.7697H16.7499L16.7407 11.8393L16.7284 10.5263L16.6454 10.2557C16.4055 9.47778 15.8982 8.84743 15.2063 8.45385C14.908 8.2878 14.7266 8.2263 14.1332 8.09101C14.0717 8.07871 14.0932 8.06641 14.2408 8.02951C14.9572 7.86347 15.5784 7.23004 15.7506 6.49514C15.8213 6.1938 15.7967 5.6188 15.7044 5.34513C15.4953 4.73323 14.9911 4.22895 14.3792 4.01986C14.1178 3.93068 13.4782 3.90916 13.1953 3.97988Z" fill="#E9E9E9"/>
                            <path d="M8.42898 6.03979C8.15839 6.09821 7.77403 6.28271 7.54649 6.4672C7.09141 6.83311 6.78392 7.48499 6.78392 8.08152C6.78392 8.67804 7.09141 9.32992 7.54649 9.69583C7.76173 9.86803 8.15224 10.0617 8.39516 10.114L8.57658 10.154L8.34904 10.1909C7.2882 10.3723 6.39033 11.1349 6.03057 12.165C5.88605 12.5801 5.86145 12.8322 5.86145 13.9576V15H8.87484H11.8882V13.9576C11.8882 12.8322 11.8636 12.5801 11.7191 12.165C11.3594 11.1349 10.4615 10.3723 9.40065 10.1909L9.17311 10.154L9.35452 10.114C9.59744 10.0617 9.98795 9.86803 10.2032 9.69583C10.6583 9.32992 10.9658 8.67804 10.9658 8.08152C10.9658 7.64796 10.7997 7.16212 10.523 6.79929C10.0556 6.18123 9.21308 5.87682 8.42898 6.03979Z" fill="#E9E9E9"/>
                        </svg>
                        </div>
                        <div class="item-name leading-5 text-sm pl-2.5" :class="selectedOptionName == 'frameworks' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isSidebarToggle">Frameworks</div>
                    </a>
                </div>
                <div v-if="sidebar.frameworks == true && !isSidebarToggle" class="absolute z-50 rounded tooltipwidth transform-y-top left-11 whitespace-nowrap">
                    <div :class="selectedOptionName == 'frameworks' ? 'px-2 text-white font-bold bg-tooltip':'px-2 text-white bg-tooltip'">Frameworks</div>
                </div>
            </li>
        </ul>
        <ul class="bottomUl mb-16">
            <li class="relative">
                <hr class="mb-4" />
                <img src="@/assets/leftarrow.svg" class=" icon   arrowIcon leftarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-if="isSidebarToggle" />
                <img src="@/assets/rightarrow.svg" class="arrowiconRight  icon  rightarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-else />
            </li>
           
        </ul>
 
        <div class="fixed z-50 bg-white w-40 px-2 py-1 flex flex-col justify-center" v-if="menuVisible">
            <div class="flex flex-row items-center cursor-pointer">
                <!-- <div class="flex flex-col mr-1 w-2/4 items-center" @click="triggerNav('monitor')">
                    <img src="@/assets/monitoring.svg" alt="" class="icn h-6 w-6 ml-2" />
                    <div class="nav-item-label-container">
                        <div class="nav-item-label">Monitor</div>
                    </div>
                </div> -->
                <div class="flex flex-row items-center cursor-pointer" @click="triggerNav('training')">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4516_14738)">
                    <path d="M0 0C7.92 0 15.84 0 24 0C24 7.92 24 15.84 24 24C16.08 24 8.16 24 0 24C0 16.08 0 8.16 0 0Z" fill="#FF9A00"/>
                    <path d="M19.6399 17.0902V8.18107C19.6399 7.67475 19.4388 7.18916 19.0808 6.83114C18.7227 6.47311 18.2371 6.27198 17.7308 6.27198H17.0945C17.0944 5.97653 17.0258 5.68513 16.894 5.42071C16.7622 5.15629 16.5708 4.92607 16.3349 4.74817C16.099 4.57027 15.8251 4.44954 15.5346 4.3955C15.2441 4.34145 14.9451 4.35556 14.661 4.43671L12.7519 4.98271C12.4741 5.06217 12.2181 5.20408 12.0036 5.39762C11.789 5.20408 11.533 5.06217 11.2552 4.98271L9.3461 4.43671C9.06201 4.35556 8.76297 4.34145 8.4725 4.3955C8.18204 4.44954 7.90809 4.57027 7.6722 4.74817C7.43632 4.92607 7.24494 5.15629 7.11314 5.42071C6.98134 5.68513 6.9127 5.97653 6.91264 6.27198H6.27628C5.76996 6.27198 5.28437 6.47311 4.92635 6.83114C4.56832 7.18916 4.36719 7.67475 4.36719 8.18107V17.0902H11.3672V18.3629H8.82173V19.6356H15.1854V18.3629H12.6399V17.0902H19.6399ZM12.6399 6.81798C12.64 6.67977 12.685 6.54534 12.7683 6.43501C12.8515 6.32467 12.9684 6.24444 13.1013 6.20643L15.0104 5.66107C15.0673 5.6446 15.1261 5.63604 15.1854 5.63562C15.3541 5.63562 15.516 5.70266 15.6353 5.822C15.7547 5.94134 15.8217 6.1032 15.8217 6.27198V10.2467L12.6399 11.1554V6.81798ZM8.18537 6.27198C8.18551 6.17354 8.20849 6.07648 8.25249 5.98843C8.29649 5.90038 8.36032 5.82373 8.43896 5.76453C8.51759 5.70532 8.6089 5.66516 8.70568 5.64721C8.80246 5.62925 8.90209 5.634 8.99673 5.66107L10.9058 6.20643C11.0387 6.24444 11.1556 6.32467 11.2388 6.43501C11.3221 6.54534 11.3671 6.67977 11.3672 6.81798V11.1554L8.18537 10.2467V6.27198ZM5.63991 8.18107C5.63991 8.0123 5.70696 7.85043 5.8263 7.73109C5.94564 7.61175 6.1075 7.54471 6.27628 7.54471H6.91264V11.2063L12.0036 12.6611L17.0945 11.2063V7.54471H17.7308C17.8996 7.54471 18.0615 7.61175 18.1808 7.73109C18.3001 7.85043 18.3672 8.0123 18.3672 8.18107V15.8174H5.63991V8.18107Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_4516_14738">
                    <rect width="24" height="24" rx="4" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    <div class="nav-item-label-container ml-2">
                        <div class="text-sm font-semibold">Training</div>
                        <div class="active-border"></div>
                    </div>
                </div>
                <!-- <div class="flex flex-col w-2/4 items-center cursor-pointer" @click="triggerNav('workflow')">
                    <img src="@/assets/monitoring.svg" alt="" class="icn h-6 w-6 ml-2" />
                    <div class="nav-item-label-container">
                        <div class="nav-item-label active">Workflow</div>
                        <div class="active-border"></div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<style scoped >
.min-h-30{
    height: 50px;
}
.w-40{
    width: 170px;
}
.w-3{
    width:15px
}
.shadow {
    --tw-shadow:5px 5px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.leftarrow ,
.rightarrow{
    /* height: 1.25rem;
    width: 1.25rem; */
    margin-top: -27px;
}
.arrowIcon{
    height:22px;
    width:22px;
    margin-left:157px;
}
.arrowiconRight{
    height:22px;
    width:22px;
    margin-left:29px;
}
.a:hover {
    color: black;
    font-weight: 400;
}
.svg-icon:hover {
    color:black;
}
.svg-icon123 div a svg path {
    fill: #152A43;
}
.svg-icon:hover path {
    fill: black; /* Change the color to your desired hover color */
}
.svg-Image:hover svg path{
    fill: #152A43;
}
.icon:hover {
transition: none !important;
}
.tooltipwidth {
    width: auto;
	z-index : 200 !important
}
.bg-tooltip{
    background-color: #152a43;
    border-radius: 3px;
}
.transform-y-top{
    transform: translateY(-28px);
}
.svg-icon123 div a svg path.systems,
.svg-icon:hover path.systems {
    fill: #e9e9e9;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
    data():any {
        return {
            isSidebarToggle: true,
            appsVisible:false,
            menuVisible: false,
            selectedOptionName: '',
            passingRouteName: '',
			sidebar: { organisations:false,teams:false,ativities:false, systems:false},
			menus: [
				// {
				// 	optionName: 'onboarding',
				// 	optionRoute: 'onboarding',
				// 	defaultoptionImage: require('@/assets/onBoardingDefault.svg'),
				// 	colouredoptionImage: require('@/assets/onBoardingColoured.svg'),
				// 	displayOptionName: 'On Boarding'
				// },
				{
					optionName: 'organisations',
					defaultoptionImage: require('@/assets/organisationDefault.svg'),
					colouredoptionImage: require('@/assets/organisationColoured.svg'),
					optionRoute: 'organisations',
					displayOptionName: 'Organisations',
				},			
				{
					optionName: 'teams',
					defaultoptionImage: require('@/assets/organisationDefault.svg'),
					colouredoptionImage: require('@/assets/organisationColoured.svg'),
					optionRoute: 'teams',
					displayOptionName: 'teams',
				},
				{
					optionName: 'activities',
					defaultoptionImage: require('@/assets/organisationDefault.svg'),
					colouredoptionImage: require('@/assets/organisationColoured.svg'),
					optionRoute: 'activities',
					displayOptionName: 'activities',
				},
				// {
				// 	optionName: 'organisationsactivity',
				// 	defaultoptionImage: require('@/assets/organisationDefault.svg'),
				// 	colouredoptionImage: require('@/assets/organisationColoured.svg'),
				// 	optionRoute: 'organisationsactivity',
				// 	displayOptionName: 'User Activity',
				// }
				]
        };
    },
       
    methods:{
        navigate(name:any, routerOption:any) {
            this.$store.dispatch('selectedToggle',this.isSidebarToggle);
            this.$store.dispatch('selectedRoute', name);
            this.$router.push({ name: routerOption });
        },
        toggleSideMenuBar() {
            this.isSidebarToggle = !this.isSidebarToggle;
            emitter.emit('toggle-sidebar', this.isSidebarToggle);
        },
        triggerNav(app:string) {
                window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
           
        },
         debouncedHandleResize() {
            clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(this.handleResize, 100); // Adjust debounce time as needed
        },
        handleResize() {
            if(window.innerWidth<1164)
            {
                this.$store.dispatch('selectedToggle', false);
                this.isSidebarToggle = false;
                emitter.emit('toggle-sidebar', this.isSidebarToggle);
            }
            else if(this.isSidebarToggle == false){
                this.$store.dispatch('selectedToggle', true);
                this.isSidebarToggle = true;
                emitter.emit('toggle-sidebar', this.isSidebarToggle);
            }
        },
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        ...mapGetters({ userInfo: 'userInfo',toggleState:'toggle'})
    },
    created(){
        this.handleResize()
    },
    mounted() {
        window.addEventListener('resize', this.debouncedHandleResize);

		if(this.$route.path.includes('/organisations') || this.$route.path.includes('/edit')){
			this.passingRouteName = 'organisations'
		}else if(this.$route.path.includes('/systems')){
			this.passingRouteName = 'systems'
		} else if(this.$route.path.includes('/teams')){
			this.passingRouteName = 'teams'
		} else if(this.$route.path.includes('/activities')){
			this.passingRouteName = 'activities'
		}else if(this.$route.path.includes('/vendors')){
			this.passingRouteName = 'vendors'
		} else if(this.$route.path.includes('/frameworks')){
			this.passingRouteName = 'frameworks'
		}
        
		this.selectedOptionName = this.passingRouteName;

        emitter.on('apps-sidemenubar', (isMenuToggle) => {
            this.menuVisible = isMenuToggle;
        });
        emitter.on('apps', (isToggle: any) => {
            this.menuVisible = isToggle;
        });

        this.isSidebarToggle = this.toggleState;

        emitter.on('toggle-sidebar', (isToggle: any) => {
        this.isSidebarToggle = isToggle;
        });
    },
     beforeUnmount() {
        window.removeEventListener('resize', this.debouncedHandleResize);
    },
});
</script>