<template>
    <div class="tableCornerRadius bg-white">
        <div class="table-container">
            <table>
                <thead>
                    <th scope="col" v-for="column in columnNames" :key="column" class="bg-white cursor-pointer capitalize" @click="sort(column)" :class="getColumnHeadWidth(column)">          
                        <div class="flex items-center justify-center" v-if="column == 'check'">
                            <input type="checkbox" class="checkBoxStyles" v-model="selectAll" :disabled="sortedData.length == 0" @change="selectAllOptions($event)"/>
                        </div>
                        <div class="flex">
                            <div v-if="column == 'criticality'">
                                <div class="pl-5 flex">
                                    {{'criticality'}}
                                    <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                </div>
                            </div> 
                            <div v-else-if="column == 'teamName'">
                                <div class="flex">
                                    {{'Team'}}
                                    <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                                </div>
                            </div> 
                            <div v-else-if="column != 'check'">{{ column }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'" v-if="column != 'check'"></div>
                        </div>
                    </th>
                    <tr v-if="visibility">
                        <td v-for="(name,index) in columnNames" class="bg-white" :class="name == 'status' ? 'flex items-center justify-center' : ''" :key="index">
                            <input type="text" placeholder="Search" class="searchForInputBoxStyling" v-model="filters[name]"/>
                        </td>
                    </tr>
                </thead>
                <tbody v-if="sortedData.length==0">
                    <tr class="cursor-pointer rowHeight">
                        <td class="relative h-10" :colspan="columnNames.size">
                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                        </td>
                    </tr>
                </tbody>
                <tbody class="nonEmptyTable" v-else>
                    <tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight" >
                        <td v-for="column,indexx in columnNames" :key="indexx" @mouseout="doFalse(column)" @click="onClickCallMethods(column,row,index)" class="cursor-pointer relative"> 
                            <div>
                                <div v-if="column == 'check'" class="flex items-center justify-center">
                                    <input type="checkbox" class="checkBoxStyles" v-model="checkedItems" :value="row" @change="changeInfo(row, $event)"/>
                                </div> 
                                <!-- <div v-else-if="column == 'criticality'">
									<div>
										{{
											criticalityData.find((l) => {
												return l.id == row[column];
											}).value
										}}
									</div>
								</div> -->
                                <div v-else-if="column == 'criticality'">
									<!-- <div class="text-center flex items-center justify-center relative rounded w-24 px-2.5 h-5 regular" :class="colorTextcriticalityStatus(row[column])">
										<div class="w-full h-full absolute opacity-10 rounded" :class="colorBgcriticalityStatus(row[column])"></div>
										<div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
											<div class="text-xs">
												{{
													criticalityData.find((l) => {
														return l.id == row[column];
													}).value
												}}
											</div>
										</div>
									</div> -->
                                    <div class="flex items-center justify-center rounded h-5 w-24 px-2.5  regular" :class="colorCriticalityBg(row[column])">
                                        <div :class="colorCriticalityText(row[column])">
                                            {{
                                                criticalityData.find((l) => {
                                                    return l.id == row[column];
                                                }).value
                                            }}
                                        </div>
                                    </div>
								</div>
                                <div v-else class="table-ellipsis" >
                                    <p class="fullText" @mousemove="toolTip(column, $event, index, row, 'text')">
                                        {{ row[column] }}
                                    </p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
    </div>
</template>
<style scoped>
.checkBoxStyles{
    height: 18px;
    width: 18px;
}
.lastChild:last-child {
    border: 0px;
}
.fullText{
    white-space: break-spaces;
    font-size: 12px;
}
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
.table-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
<script lang="ts">
import { emitter } from '@/main';
import _ from 'lodash';
import { defineComponent } from 'vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default defineComponent({
    props: ['tableRowData','headersData','openPopup','navigate', 'buttonType'],
    data(): any {
        return {
            tableHead: [],
            tableRow: [],
            searchTableRow: [],
            visibility: false,
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',

            currentPage: '',
            pageSize: '',
            dynamicPagination:'',

            selectAll: false,
            checkedItems: [],
            dummyCheckedItems: [],
            perviousTableData: [],

            pop: false,
            styling: { left: '', bottom: '' , top : '' , right : ''},
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            filters: {},
            selectedRowIndex:'',
            pageType:false,
            showDropDown: false,
            criticalityData:[
                {
                    id:1,
                    value:'Very Low'
                },
                {
                    id:2,
                    value:'Low'
                },
                {
                    id:3,
                    value:'Moderate'
                },
                {
                    id:4,
                    value:'High'
                },
                {
                    id:5,
                    value:'Very High'
                }
            ],
            cacheObj:{}
        };
    },
    components: {
        pagination,
    },
    async mounted() {

        this.initialize();
        emitter.on('visible', (isBoolean: any) => {
            this.visibility = isBoolean;
			this.visibility == false ? (this.filters = {}) : '';
            
        });
        emitter.on('*', (type, booleanValue) => {
        	if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
        		this.showDropDown == true ? (this.showDropDown = false) : '';
        	} else {
        		if (this.showBlock === true && type != 'deActiviteToolTip' ) {
        			this.showDropDown == true ? (this.showDropDown = booleanValue) : '';
        		} else if (this.showBlock === false) {
        			this.showDropDown == true ? '' : (this.showDropDown = booleanValue);
        			this.showBlock = true;
        		}
        	}
        });
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
        }),
        sortedData: function (): any {
            return [...this.tableRow]
                .filter((row: any) => {
                    for (const key in this.filters) {
                        const inputValue = this.filters[key];
                        if (row[key] == '') {
                                return false;
                            }
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (String(row[key]).toLowerCase().includes(String(inputValue).toLowerCase()) === false) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != '_id') names.add(key);
            }
            return names;
        }
    },
    watch: {
       visibility(newValue) {
			if (newValue == false) {
				this.initialize();
			}
		},
		tableRowData: {
			handler(newVal, oldVal) {
				this.tableRow = this.tableRowData;
				this.initialize();
			},
			deep: true
		}
    },
    methods: {
        initialize() {
            this.tableRow = this.tableRowData;
            this.searchTableRow = this.tableRowData;
            this.selectAll= true
            this.selectAllOptions();
            this.changeInfo()
        },
        isNearBottom(index:any) {
			if(((index == 0)||(index == 1))&&((this.sortedData.length == 1)||(this.sortedData.length == 2))){
				return index >= this.sortedData.length - 2;
			}else{
				return index >= this.sortedData.length - 2;
			}
		},
        paginationUpdations(currentPage:any,pageSize:any){
            this.currentPage=currentPage;
            this.pageSize=pageSize;
        },
        sort(tableHead: any) {
            this.className = tableHead;
            if (tableHead === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = tableHead;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.tipText = row[column];
			this.tipColumn = column;
			this.tipIndex = index;

			switch (status) {
				case 'text':
					this.handleTextTooltip(e,column);
					break;
            }
		},
        handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                let obj:any = {
                    tipText : this.tipText
                }
              emitter.emit('activiteToolTip',obj)
            }
        },
        isTextOverflowed(element:any) {
            return element.scrollHeight > element.clientHeight;
        },
        doFalse(column:any) {
            emitter.emit('deActiviteToolTip')
        },
        changeInfo(risk: any, e: any) {
            if (this.checkedItems.length === this.tableRow.length) {
                this.selectAll = true;
            } else {
                this.selectAll = false;
            }
            this.emitingCheckedItems();
        },
        selectAllOptions(e: any) {
            this.checkedItems = [];
            // if(this.selectedActivitiesList.length == 0){
                
            // } else{
                 
            //     this.selectedActivitiesList = this.activitiesList
            // }
            if(e!= undefined){
                const checked = e.target.checked;
                if(checked){
                    for (let obj of this.tableRow) {
                        this.checkedItems.push(obj);
                        this.objLen = this.checkedItems.length;
                    }
                }else{
                    this.checkedItems = []
                }
            }else{
                // if(this.selectAll == true && this.buttonType == 'front'){
                //     console.log("1111111")
                //     for (let obj of this.tableRow) {
                //         this.checkedItems.push(obj);
                //         this.objLen = this.checkedItems.length;
                //     }
                // }else if(this.buttonType == 'back'){
                    
                    let a:any = localStorage.getItem('onBoard');
                    // console.log("aaaaaaaaaa", a)
                    if(a!=null && a!= undefined){
                        
                        let b = JSON.parse(a)
                        this.cacheObj = b;
                    }
                    // console.log("tableRow", this.tableRow);
                    let obj: any = localStorage.getItem('tableData');
                    if(obj != undefined || obj != null){
                        let qq = JSON.parse(obj);
                        this.perviousTableData = qq;
                    }
                    
                    // console.log("this.cacheObj.detailData[1].values", this.cacheObj.detailData[1].values)
                    this.dummyCheckedItems = this.cacheObj.detailData[1].values
                    // console.log("dummyCheckedItems", this.dummyCheckedItems);
                    // console.log("perviousTableData", this.perviousTableData);
                    let finalDiff: any = []

                    if(this.perviousTableData != undefined && this.perviousTableData.length != 0 && this.tableRow != undefined && this.tableRow.length != 0){
                        // Find objects in perviousTableData but not in tableRow (based on _id)
                        const diffPrevious = this.perviousTableData.filter((item1:any) => 
                            {return !this.tableRow.some((item2:any) => item1._id == item2._id)}
                        );

                        // Find objects in tableRow but not in perviousTableData (based on _id)
                        const diffTableRow = this.tableRow.filter((item1:any) => 
                            {return !this.perviousTableData.some((item2:any) => item1._id == item2._id)}
                        );

                        // Combine the differences
                        finalDiff = [...diffPrevious, ...diffTableRow];
                        // console.log("finalDiff", finalDiff);
                    }
                    if(this.dummyCheckedItems.length > 0){
                        console.log("1111111")
                        // this.cacheObj.detailData[1].values.map((obj:any)=>{
                        //     this.checkedItems.push(obj)
                        // })
                        // this.tableRow
                        // this.cacheObj.detailData[1].values.
                        
                        this.tableRow.map((row: any)=>{
                            let a 
                            a = this.cacheObj.detailData[1].values.find((obj: any)=> {return obj._id == row._id})
                            if(a!=undefined){
                                this.checkedItems.push(a);
                            }
                        })
                        console.log("this.checkedItems", this.checkedItems)
                    } 
                    // else if(this.dummyCheckedItems.length > 0){
                    //     this.tableRow.map((row: any)=>{
                    //         let a 
                    //         a = this.cacheObj.detailData[1].values.find((obj: any)=> {return obj._id == row._id})
                    //         if(a!=undefined){
                    //             this.checkedItems.push(a);
                    //         }
                    //     })
                    // } 
                    else if (this.dummyCheckedItems.length == 0){
                        console.log("2222222222")
                        for (let obj of this.tableRow) {
                            this.checkedItems.push(obj);
                            this.objLen = this.checkedItems.length;
                        }
                    } 
                    if(finalDiff != undefined && finalDiff.length > 0){
                        for (let obj of this.tableRow) {
                            let row = finalDiff.find((final: any)=>{return final._id == obj._id})
                            if(row != undefined){
                                this.checkedItems.push(row);
                            }
                        }
                    }

                    
                // } else if(this.buttonType == 'front'){
                //     console.log("333333")
                //     this.cacheObj.detailData[1].values.map((obj:any)=>{
                //         this.checkedItems.push(obj)
                //     })
                // }
            }



            // if (this.buttonType == 'front') {
            //     for (let obj of this.tableRow) {
            //         this.checkedItems.push(obj);
            //         this.objLen = this.checkedItems.length;
            //     }
            // } else if(this.buttonType == 'back'){
            //     let a:any = localStorage.getItem('onBoard');

            //     if(a!=null && a!= undefined){
            //         let b = JSON.parse(a)
            //         this.cacheObj = b
            //     }

            //     this.cacheObj.detailData[1].values.map((obj:any)=>{
            //         this.checkedItems.push(obj)
            //     })
            // }
            this.emitingCheckedItems();
        },
        emitingCheckedItems(){
            console.log("checkedItems", this.checkedItems)
            emitter.emit('checkedactivity', this.checkedItems)
        },
        colorCriticalityText(value: any) {
            if (value == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 2) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 4) {
                return ['text-error font-semibold'];
            } else if (value == 5) {
                return ['text-darkred font-semibold'];
            }
        },
        colorCriticalityBg(value: any) {
            if (value == 1) {
                return ['bg-veryLowColor border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['bg-lowColor border-solid border border-green'];
            } else if (value == 3) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (value == 4) {
                return ['bg-highColor border-solid border border-error'];
            } else if (value == 5) {
                return ['bg-veryHighColor border-solid border border-darkred'];
            }
        },
        getColumnHeadWidth(column: any) {
            if( column == 'check'){
                return 'w-16';
            }else if(column == 'team' || column=='teamName') {
                return 'w-32';
            }else if(column == 'criticality') {
                return 'w-36';
            }else if(column == 'classification') {
                return 'w-40';
            }else if(column == 'activity') {
                return 'w-80';
            }else {
                return '';
            }
        },
        onClickCallMethods(column:any,row:any,index:any){
            if(column == 'Action'){
                this.clickedOnActionButton(row, index, column)
            }else if(column == 'deleteIcon'){
                 this.emitDeleteRowEvent(row,index)
            }else{
                this.actionOnClickingTableRow(row)
                this.showBlock = false;
            }
        //    column=='Action'? clickedOnActionButton(row, index, column) :  actionOnClickingTableRow(row) 
        },
        actionOnClickingTableRow(obj:any){
            emitter.emit('dataTransfering', obj);
        },
    },
});
</script>

