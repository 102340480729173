<template>
	<div id="body-container">
		<navbar />
		<div class="flex" @click="closeApps()">
			<div :class="{ 'w-40': isTogglingData, 'w-10': !isTogglingData }">
				<sidemenubar />
			</div>
			<div class="content flex-1 w-full h-screen overflow-y-scroll" :class="{ toggledstate: isTogglingData }">
				<div :class="controlMappingScreen == true ? 'page1':'page'">
					<slot />
				</div>
				<Footer />
			</div>
		</div>
	</div>
</template>
<style  scoped>
.page {
	margin-bottom: 80px;
}
.page1 {
    margin-bottom: 60px;
}
</style>
 <script lang="ts">
import navbar from '@/components/navbar.vue';
import sidemenubar from '@/components/sidemenubar.vue';
import Footer from '@/components/footer.vue';
import { defineComponent } from 'vue';
import { emitter } from '../main';
import {mapGetters} from 'vuex';
export default defineComponent({
	components: {
		navbar,
		sidemenubar,
		Footer
	},
	data() {
		return {
			isTogglingData: true,
			appsVisible:false,
			controlMappingScreen:false,
		};
	},
	
	computed:{
		...mapGetters({ selectedOptionName: 'val',toggleState:'toggle' })
	},
	methods:{
		closeApps(){
		this.appsVisible=false;
		emitter.emit('apps', this.appsVisible);
		},
	},

	mounted() {
		this.isTogglingData = this.toggleState;
		emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isTogglingData = isToggle;
		});
		if((this.$route.path.includes('/onBoardSystemInformation'))||(this.$route.path.includes('/onBoardActivitiesInfo'))||(this.$route.path.includes('/edit'))){
            this.controlMappingScreen = true
        } else {
            this.controlMappingScreen = false
        }
	}
});
</script>