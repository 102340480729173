<template>
	<div id="body-container">
		<newNavBar />
		<div class="content flex-1 w-full h-screen overflow-y-auto">
			<div class="page">
				<slot />
			</div>
			<Footer />
		</div>
	</div>
</template>
<style  scoped>
.page {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .page {
    margin-bottom: 80px;
  }
}
</style>
 <script lang="ts">
import newNavBar from '@/components/newNavBar.vue';
import sidemenubar from '@/components/sidemenubar.vue';
import Footer from '@/components/newFooter.vue';
import { defineComponent } from 'vue';
import { emitter } from '../main';
import {mapGetters} from 'vuex';
export default defineComponent({
	components: {
		newNavBar,
		sidemenubar,
		Footer
	},
	data() {
		return {
			isToggling: false,
			appsVisible:false,
		};
	},
});
</script>