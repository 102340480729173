<template>
    <div class="pt-3 pl-5 pr-5 overflow-y-hidden">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Activities</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex items-center" @click="filterOpen" :class="activitiesArray.length == 0?'btndisabled pointer-events-none':'filterButton h-8'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                    <div class="text-center">
                      <button class="btnprimary mr-2" @click="showPopUp()">Add</button>
                   </div>
                </div>
            </div>
        </div>
        <div class="my-3 text-xs">
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
        </div>
        
        <div>
            <newTable :tableRowData="activitiesArray" :headersData="headers" :tableProps="tableProps" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>

            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
        </div>
        <popup v-if="showAddPopup" :popUp="showAddPopup">
			<template v-slot:header>
				<div class="flex items-center justify-between m-4 rounded">
					<h1>Activity</h1>
					<button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
						<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
					</button>
				</div>
			</template>
			<fieldset>
				<div class="popup-business">
                    <div>
                        <div>
                            <div class="popupbodycontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="upperspacing w-full">
                                            <label class="controllabel text-xs">Activity Name</label>
                                            <input class="inputboxstyling mt-2" placeholder="Activity Name"  v-model.trim="activitiesObj.name" @input="v$.activitiesObj.name.$touch()" :class="{'is-invalid': v$.activitiesObj.name.$error}" />
                                            <div v-if="v$.activitiesObj.name.$error " class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.activitiesObj.name.required.$invalid ">Required</div>
                                                <div v-else-if="v$.activitiesObj.name.required && v$.activitiesObj.name.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                            </div>
                                    </div>
                                    <div class="row flex flex-row upperspacing w-full gap-3">
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Department</label>
                                            <multiselect
                                                v-model="activitiesObj.departments"
                                                :options="teamsOptions" 
                                                :showNoOptions="false"
                                                @search-change="asyncdepartmentDisplayFind"
                                                :multiple="false"
                                                label="label"
                                                track-by="value"    
                                                :searchable="true"  
                                                :close-on-select="true"
                                                :show-labels="false"
                                                :hide-selected="true"
                                                placeholder="Department"
                                                open-direction="bottom"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                    'is-invalid borderRed': v$.activitiesObj.departments.$error,
                                                    'has-options': teamsOptions && teamsOptions.length > 0,
                                                    'no-options': !teamsOptions.length,
                                                }"
                                            >
                                                <template v-slot:noResult>
                                                    <span class="text-xs">No departments Found</span>
                                                </template>
                                            </multiselect>
                                            <div v-if="v$.activitiesObj.departments.$error " class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.activitiesObj.departments.required.$invalid ">Required</div>
                                            </div>
                                        </div>
                                        <div class="w-1/2">
                                            <label class="controllabel text-xs">Criticality</label>
                                            <select
                                                class=""
                                                v-model="activitiesObj.criticality"
                                                :class="{
                                                    'is-invalid': v$.activitiesObj.criticality.$error,'inputboxstylingForSelectAtPopup': activitiesObj.criticality === '','stylingForInputboxstylingForSelectAtPopup': activitiesObj.criticality !== ''
                                                }"
                                            >
                                                <option value="" selected hidden>Please select one</option>
                                                <option class="optionColors" v-for="option in criticalityoptions" :key="option.id" :value="option.id">
                                                    {{ option.value }}
                                                </option>
                                            </select>
                                            <div v-if="v$.activitiesObj.criticality.$error " class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.activitiesObj.criticality.required.$invalid ">Required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="upperspacing w-full">
                                            <label class="controllabel text-xs">Description</label>
                                            <textarea
                                                class="inputboxstyling"
                                                placeholder="Description"
                                                v-model.trim="activitiesObj.description"
                                                @input="v$.activitiesObj.description.$touch()"
                                                :class="{
                                                    'is-invalid': v$.activitiesObj.description.$error
                                                }"
                                            />
                                            <div v-if="v$.activitiesObj.description.$error " class="text-red-500">
                                                <div class="error-text text-xs" v-if="v$.activitiesObj.description.required.$invalid ">Required</div>
                                                <div v-else-if="v$.activitiesObj.description.required && v$.activitiesObj.description.restrictCharacters1.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                            </div>
                                    </div>
                                </div>
                                
                                <div v-if="editPopup == false" class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                                    <button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Reset</button>
                                    <button class="" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickSaveBtn(activitiesObj)">Save</button>
                                </div>
                                <div v-else class="buttonposition flex justify-center items-center mb-4 mx-0 mt-4">
                                    <button class=" mr-2" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onCancelbtn()">Cancel</button>
                                    <button class="" :disabled="clickOnButton" :class="(JSON.stringify(dummyactivitiesObj) == JSON.stringify(activitiesObj)) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="onClickUpdateBtn(activitiesObj)">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</fieldset>
		</popup>
    </div>
</template>

<style>

.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
.borderRed {
    border: 1px solid red !important;
}
.criticality {
    padding-left: 12px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import newTable from '@/components/newTable.vue';
import popup from '@/components/sidePopup.vue';
import toolTip from '@/components/toolTip.vue';
import { emitter, toast } from '@/main';
import multiselect from 'vue-multiselect';
import { required } from '@vuelidate/validators';
import { helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
const restrictCharacters = helpers.regex(/^.{0,20}$/);
const restrictCharacters1 = helpers.regex(/^.{0,300}$/);

export default defineComponent({
    data() :any{
        return {
            v$:useVuelidate(),
            show:false,
            clickOnButton:false,
            showAddPopup:false,
            activitiesArray:[],activitiesData:[],
            headers:['name','description','departments','criticality'],
            dataLoading:true,
            toolTipText:'',
            toolTipActive:false,
            dummyList:[],
            teamsArray:[],
            teamsArray1:[],
            editPopup: false,
            teamsOptions:[],
            dummyactivitiesObj:{},
            showFilters:false,
			searchValue:''	,
            activitiesObj: {
                name:'',
                departments:'',
                description:'',
                criticality:'',
            },
            criticalityoptions: [
                {
                    id:1,
                    value:'Very Low'
                },
                {
                    id:2,
                    value:'Low'
                },
                {
                    id:3,
                    value:'Moderate'
                },
                {
                    id:4,
                    value:'High'
                },
                {
                    id:5,
                    value:'Very High'
                }
            ],
            tableProps:{
                headerNames:[{key:'name',value:'Name'},{key:'description',value:'Description'},{key:'departments',value:'Team'},{key:'criticality',value:'Criticality'}],
                
                headerWidth:[{key:'name',value:'w-1/5'},{key:'description',value:'w-1/2'},{key:'team',value:''},{key:'criticality',value:'text-center w-1/6'}],
            }
        }
    },
    components:{
        toolTip,
        popup,
        newTable,multiselect
    },
    validations() {
        return {
            activitiesObj:{
                name:{ required,restrictCharacters },
                departments:{ required },
                description:{ required,restrictCharacters1 },
                criticality:{ required },
            }
        }
    },
    methods:{
       filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.activitiesArray;
            } else {
                this.activitiesArray = this.dummyList;
            }
            emitter.emit('visible',this.showFilters)
        },
        onCancelButtonAddPopUp(){
            this.v$.$reset();        
            this.showAddPopup = false
            this.activitiesObj={}
            this.activitiesObj.name=''
            this.activitiesObj.description=''
            this.activitiesObj.criticality=''
        },
        onCancelbtn(){
            this.v$.$reset();
            if(this.editPopup == false){
            this.activitiesObj={}
            this.activitiesObj.name=''
            this.activitiesObj.description=''
            this.activitiesObj.criticality=''
            } else {
                this.activitiesObj = {...this.dummyactivitiesObj}
            }
        },
		asyncdepartmentDisplayFind(query:any){
			if(query == ''){
				console.log("intooo empty",query)
				this.teamsOptions = []
			}else if(query.length > 1){
				this.teamsOptions = this.teamsArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        async getAllTeams(){
            this.teamsArray=[]
            await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/teams/getall`).then((res: any) => {
				let teamData:any = res.data
                console.log("lkjhfgdlk2",teamData)
                let obj:any =[]
                teamData.forEach((team:any)=>{
                    obj.push({label: team.name, value :team._id})
                    this.teamsArray=obj
                })
                console.log("lkjhfgdlk22",obj)
                console.log("lkjhfgdlk1",this.teamsArray1)

			})
        },
        async getAllActivities(){
            this.activitiesArray=[]
            this.activitiesData=[]
            this.dataLoading = true
            await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/root/activities/getall`).then((res: any) => {
				this.activitiesData = res.data
			})
                this.activitiesData.forEach((activity:any)=>{
                let activitydept:any
                let activitycriticality:any
                if(activity.departments != undefined){
                    activity.departments.map((dept:any)=>{
                        const department = this.teamsArray.find((team: any) => team.value === dept);

                        if (department) {
                            activitydept = department; 
                        }
                    })
                }
                    
                    activitycriticality = this.criticalityoptions.find((obj:any)=>{return obj.id == activity.criticality})
                    
                    let obj:any = {
                        _id:activity._id,
                        name:activity.name,
                        description:activity.description,
                        departments:activitydept!=null||activitydept!=undefined?activitydept.label:'N/A',
                        criticality:activitycriticality.id
                    }
                    this.activitiesArray.push(obj)
                })
                this.dataLoading = false
                console.log('cameobject111',this.activitiesData)
        },
        async onClickUpdateBtn(obj:any){
            this.v$.$touch();
            console.log("kjdfgh",this.v$.activitiesObj.$invalid)
                console.log("kjdfgh",obj)
                if(!this.v$.activitiesObj.$invalid){
                
                this.clickOnButton = true
                    //     /root/teams/activities/mapping/:id

                    //     // payload:
                    //     //     {
            //     "name":"activity-04",
            //     "departments":["673ecb9d514c39a4d309a55f"],
            //     "criticality":5
            // }
            const departmentValues = obj.departments.value;

            // Construct the payload
            let id = obj._id
            const payload = {
                name: obj.name,  // Only send the 'value' of the department
                criticality: obj.criticality,
                description: obj.description,
                departments: [departmentValues],
            };
            
            this.dataLoading = true
            console.log("fsdhjg",payload)
            try {
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/teams/activities/mapping/${id}`,payload).then((res: any) => {
                    console.log("fsdhjg",payload)
                    toast.info('Updated Sucessfully', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
                await this.getAllActivities();
                this.showAddPopup = false
                
            this.activitiesObj={}
            this.activitiesObj.name=''
            this.activitiesObj.description=''
            this.activitiesObj.criticality=''
                this.v$.$reset();
                
            this.dataLoading = false
        }
        },
        async onClickSaveBtn(obj: any){
            this.v$.$touch();
            if(!this.v$.activitiesObj.$invalid){
                this.clickOnButton = true
                this.dataLoading = true
                // endpoint:/root/activities/add

                // payload:
                //     [{
                //             "name":"activity-2",
                //             "departments":["673ecb98514c39a4d309a55e","673ecb9d514c39a4d309a55f"],
                //             "criticality":4,
                //         "description":"activity-02"
                //     }]
                const deptArray = [obj.departments.value]
                const payload = [{name:obj.name,departments:deptArray,criticality:obj.criticality,description:obj.description}]
            try {
                await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/root/activities/add`,payload).then((res: any) => {
                    console.log("fsdhjg",payload)
                    toast.info('Updated Sucessfully', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                });
                this.showAddPopup = false
                await this.getAllActivities();
            } catch (e) {
                toast.error('error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            } finally {
                this.clickOnButton = false
            }
            this.v$.$reset();
            this.activitiesObj={}
            this.activitiesObj.name=''
            this.activitiesObj.description=''
            this.activitiesObj.criticality=''

            }
            this.dataLoading = false
        },
        showPopUp(){
            this.editPopup = false
            this.showAddPopup = true
            this.dummyactivitiesObj = {...this.activitiesObj}
        },
        showEditPopup(data: any) {
            // console.log("object ochhindi",data)
            this.$router.push({ name: 'onBoardActivitiesInfo', params: { activityId: data._id } });
            // let activityDept:any
            // data.departments.forEach((deptId: any) => {
            //     activityDept = this.teamsArray.find((team: any) => team.value === deptId);
                
            // });

            // // data.departments = activityDept
            // let obj:any={
            //     _id:data._id,
            //     name:data.name,
            //     description:data.description,
            //     criticality:data.criticality,
            //     departments:activityDept
            // }
            // console.log("hjgkhgjk",obj)
            // this.activitiesObj = {...obj };
            // this.dummyactivitiesObj = { ...this.activitiesObj }; 
            // this.showAddPopup = true;
            // this.editPopup = true;
        }

    },
    async mounted(){
        this.dataLoading = true;
        await this.getAllTeams();
        await this.getAllActivities();
        this.dataLoading = false;
        emitter.off('dataTransfering');
        emitter.on('dataTransfering', (isData: any) => {
            console.log('cameobject3245',this.activitiesData)
            this.activitiesData.forEach((activity: any) => {
                if(isData._id == activity._id){
                    this.showEditPopup(activity)
                }
            });
        })
        
        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    }
})
</script>
