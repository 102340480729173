<template>
  <div class="pt-3 pl-5 pr-5 overflow-y-hidden">
    <div class="row w-full justify-between flex" style="align-items: center">
      <h1 class="title">Vendors</h1>
      <div class="flex items-center">
        <button
          class="btnprimary mr-2"
          @click="showAddPopup()"
        >
          Add
        </button>
        <div class="flex flex-row-reverse items-center">
          <button
            class="flex items-center"
            @click="filterOpen"
            :class="
              vendorsDataArray.length == 0
                ? 'btndisabled pointer-events-none'
                : 'filterButton h-8'
            "
          >
            Filters
            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
          </button>
        </div>
      </div>
    </div>
    <div class="mt-3 text-xs">
      The Global Risks Report 2022, 17th Edition, is published by the World
      Economic Forum. The information in this report, or on which this report is
      based, has been obtained from sources that the authors believe to be
      reliable and accurate. However, it has not been independently verified and
      no representation or warranty, express or implied, is made as to the
      accuracy or completeness of any information obtained from third parties.
    </div>
    <div class="mt-3">
      <newTable
        :tableRowData="vendorsDataArray"
        :headersData="headers"
        :tableProps="tableProps"
        :openPopup="false"
        :navigate="true"
        :loadData="dataLoading"
      ></newTable>
      <div v-if="toolTipActive">
        <tool-tip :tipText="toolTipText"></tool-tip>
      </div>
    </div>
    <popup v-if="showVendorsPopUp">
      <template v-slot:header>
        <div class="flex items-center justify-between py-4 px-2 rounded">
          <h1 class="pl-2 w-60">Vendors</h1>
          <button
            class="float-right text-3xl"
            @click="onCancelbtn('close')"
          >
            <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
          </button>
        </div>
      </template>
      <fieldset>
        <div class="popup-priority">
          <div class="statuscontainer popupbodycontainer">
            <div class="row flex flex-row w-full upperspacing">
              <div class="w-full">
                <label class="controllabel">Name</label>
                <input
                    type="text"
                    v-model.trim="vendorsObj.name"
                    @input="v$.vendorsObj.name.$touch()"
                    placeholder="Name"
                    class="inputboxstyling"
                    :class="{
                        'is-invalid': (v$.vendorsObj.name.$error || checkVendorName),
                    }"
                />
                <div
                  class="text-red-500"
                  v-if="v$.vendorsObj.name.$error || checkVendorName"
                >
                  <div v-if="v$.vendorsObj.name.required.$invalid" class="error-text text-xs" >Required </div>
                  <div v-else-if="checkVendorName" class="invalid-feedback text-xs">Vendor Name already exists.</div>
                  <div v-else-if="v$.vendorsObj.name.required && v$.vendorsObj.name.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                </div>
              </div>
            </div>

            <div class="row flex justify-between flex-row w-full upperspacing">
              <div class="w-1/2">
                <label class="controllabel">Legal Name</label>
                <input
                  type="text"
                  v-model.trim="vendorsObj.legalName"
                  placeholder="Legal Name"
                  @input="v$.vendorsObj.legalName.$touch()"
                  class="inputboxstyling"
                  :class="{
                    'is-invalid': (v$.vendorsObj.legalName.$error || checkLegalName),
                  }"
                />
                <div class="text-red-500" v-if="v$.vendorsObj.legalName.$error || checkLegalName">
                  <div v-if="v$.vendorsObj.legalName.required.$invalid" class="error-text text-xs" >Required</div>
                  <div v-else-if="checkLegalName" class="invalid-feedback text-xs">Legal Name already exists.</div>
                  <div v-else-if="v$.vendorsObj.legalName.required && v$.vendorsObj.legalName.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                </div>
              </div>
              <div class="w-1/2 leftspacing">
                <label class="controllabel">Category</label>
                <multiselect
                  v-model.trim="vendorsObj.vendorCategory"
                  :options="vendorCatgeoryDisplay"
                  @search-change="asyncVendorCatgeoryData"
                  :multiple="false"
                  :searchable="true"
                  :hide-selected="true"
                  open-direction="bottom"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :hideArrow="false"
                  placeholder="Category"
                  deselectLabel=""
                  :showNoOptions="false"
                  selectLabel=""
                  class="custom-multiselect userSelect"
                  :class="{
                    'is-invalid borderRed': v$.vendorsObj.vendorCategory.$error,
                    'has-options': vendorCatgeoryDisplay && vendorCatgeoryDisplay.length > 0,
                    'no-options': !vendorCatgeoryDisplay.length &&  searchValue !== '',

                  }"
              >
              </multiselect>
                <!-- <input
                  type="text"
                  v-model.trim="vendorsObj.vendorCategory"
                  placeholder="Category"
                  class="inputboxstyling"
                  @input="v$.vendorsObj.vendorCategory.$touch()"
                  :class="{
                    'is-invalid': v$.vendorsObj.vendorCategory.$error,
                  }"
                /> -->
                <div class="text-red-500" v-if="v$.vendorsObj.vendorCategory.$error">
                  <div v-if="v$.vendorsObj.vendorCategory.required.$invalid" class="error-text text-xs">Required</div>
                  <div v-else-if="v$.vendorsObj.vendorCategory.required && v$.vendorsObj.vendorCategory.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                </div>
              </div>
            </div>
            <div class="row flex flex-row w-full upperspacing">
              <div class="w-full">
                <label class="controllabel">Website</label>
                <input
                  type="text"
                  v-model.trim="vendorsObj.website"
                  placeholder="Website"
                  class="inputboxstyling"
                  @input="v$.vendorsObj.website.$touch()"
                  :class="{
                    'is-invalid': v$.vendorsObj.website.$error || checkWebsiteLinks,
                  }"
                />
                <div class="text-red-500" v-if="v$.vendorsObj.website.$error || checkWebsiteLinks">
                  <div v-if="v$.vendorsObj.website.required.$invalid" class="error-text text-xs" >Required</div>
                  <div v-else-if="checkWebsiteLinks" class="invalid-feedback text-xs">Website already exists.</div>
                  <div v-else-if="v$.vendorsObj.website.required && v$.vendorsObj.website.restrictCharacters100.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                  <div v-else-if="v$.vendorsObj.website.required &&  v$.vendorsObj.website.websiteValidate.$invalid" class="error-text text-xs">Invalid Website</div>
                </div>
              </div>
            </div>
            <div class="row w-full upperspacing">
                <label class="controllabel">Logo</label>
                <div v-if="vendorsObj.imageUrl == '' && !vendorsObj._id" :class="{'is-invalid borderRed': v$.fileName.$error,}" class="border w-full rounded py-2 border-lightgrey">
                    <div class="flex justify-center">
                        <div>
                            <div class="flex justify-center">
                                <label :htmlFor="vendorsObj" class="mt-2">
                                    <img src="@/assets/addImage.svg" alt="" class="w-20 h-20 cursor-pointer" />
                                </label>
                                <label :htmlFor="vendorsObj">
                                    <input type="file" :id="vendorsObj" style="display: none" ref="fileInput" @change.stop="updateFileName('fileInput','add')"/>
                                </label>
                            </div>
                            <div class="flex justify-center items-center mt-2">
                                <div v-if="fileName!=''"><span class="text-xs">Selected File : </span><span class="text-sm font-bold">{{fileName}}</span>	</div>
                                <div v-else-if="fileName == ''" class="text-xs">{{'Click on the icon for uploading a file'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="vendorsObj._id && vendorsObj.imageUrl !=''" class="border w-full rounded py-2  border-lightgrey">
                    <div class="flex justify-center p-2 items-center">
                        <label :htmlFor="vendorsObj" class="mt-2">
                            <img :src="vendorsObj.imageUrl" alt="" class="w-20 h-20 cursor-pointer" />
                        </label>
                        <label :htmlFor="vendorsObj">
                            <input type="file" :id="vendorsObj" style="display: none" ref="fileInput" @change.stop="updateFileName('fileInput','edit')"/>
                        </label>
                    </div>
                    <div class="flex justify-center items-center mt-2">
                        <div v-if="fileName!=''"><span class="text-xs">Selected File : </span><span class="text-sm font-bold">{{fileName}}</span></div>
                    </div>
                </div>
                <div v-if="v$.fileName.$error" class="text-red-500">
                    <div class="error-text text-xs" v-if="v$.fileName.required.$invalid">Required</div>
                </div>
            </div>
          </div>
          <div
            class="buttonposition flex justify-center items-center mb-4 mx-0"
          >
            <button :class="JSON.stringify(vendorsObj) == JSON.stringify(this.dummyVendor) && fileName == '' ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5"  @click="onCancelbtn('cancel')">Cancel</button>
            <button :class="JSON.stringify(vendorsObj) == JSON.stringify(this.dummyVendor) && fileName == '' ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5" v-if="!vendorsObj._id" @click="addFramework()">Save</button>
            <button :class="JSON.stringify(vendorsObj) == JSON.stringify(this.dummyVendor) && fileName == '' ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5" @click="editFramework()" v-else >Update</button>
          </div>
        </div>
        
      </fieldset>
    </popup>
    <!-- <spinner v-if="spinnerLoading" /> -->
  </div>
</template>
<style scoped>
.controllabel {
  font-size: 12px;
}
.labelMarginTop {
  margin-top: 8px;
}
.empty tr td {
  border-bottom: 1px solid #e9e9e9 !important;
}
.placeholder-styles {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
}
.uploadedFileNameStyles {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  color: #4d4d4d;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import newTable from "@/components/newTable.vue";
import popup from "@/components/sidePopup.vue";
import { emitter, toast } from "@/main";
// import Multiselect from '@vueform/multiselect';
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Multiselect from "vue-multiselect";
import { helpers } from '@vuelidate/validators';
import toolTip from "@/components/toolTip.vue";
import { v4 as uuidv4 } from 'uuid';
const restrictCharacters = helpers.regex(/^.{0,20}$/);
const restrictCharacters100 = helpers.regex(/^.{0,100}$/);

const websiteValidate = helpers.regex(/^(https?:\/\/)?(www\.)?((?!www)[a-zA-Z0-9-]{2,256}\.(?!www)[a-zA-Z]{2,20}(\.(?!www)[a-zA-Z]{2,20}){0,2})(:(?:[0-5]?[0-9]{1,4}|[1-5][0-9]{1,3}|655[0-3][0-5]))?(\/[a-zA-Z0-9\-._~:/#\\]*)?$/g);

export default defineComponent({
  data(): any {
    return {
      v$: useVuelidate(),
      headers: ["name", "legalName", "vendorCategory", "website"],
      showVendorsPopUp: false,
      // spinnerLoading: false,
      regionObj: {
        region: "",
        regionHeadQuaters: "",
        regionOwner: "",
      },
      vendorsDataArray: [],
      tableProps: {
        headerNames: [
          { key: "legalName", value: "Legal Name",  },
          { key: "name", value: "Name" },
          { key: "vendorCategory", value: "Category",  },
          { key: "website", value: "Website" },
        ],

        headerWidth: [
          { key: "legalName", value: "w-1/4" },
          { key: "name", value: "w-1/4" },
          { key: "vendorCategory", value: "w-1/4",  },
          { key: "website", value: "w-1/4" },
        ],
      },
      showFilters: false,
      vendorsArray: [],
      toolTipActive: false,
      toolTipText: "",
      fileName: "",
      dummyVendor: {},
      clickOnButton: false,
      filteredVendorNames: [],
      filteredLegalNames: [],
      perviousVendorNames: [],
      perviousLegalNames: [],
      perviousWebsiteLinks: [],
      filteredWebsiteLinks: [],
      vendorCatgeoryList:[],
      vendorCatgeoryDisplay:[],
      searchValue:'',
      presentImageUrl: '',
      presentFile: '',
      dummyList: [],
      dataLoading: true,
      vendorsObj: {
        name: "",
        vendorCategory: "",
        legalName: "",
        website: "",
        imageUrl: "",
      },
    };
  },
  components: {
    newTable,
    popup,
    Multiselect,
    toolTip,
    // spinner
  },
  validations() {
    return {
      vendorsObj: {
        name: { required, restrictCharacters },
        vendorCategory: { required, restrictCharacters },
        legalName: { required, restrictCharacters },
        website: { required ,restrictCharacters100,websiteValidate},
      },
      fileName:{required}
    };
  },
  computed: {
    ...mapGetters({ userInfo: "userInfo" }),
    checkVendorName: function (): any {
        const trimmedVendorNames = this.vendorsObj.name.trim().replace(/\s+/g, '').toLowerCase();
        console.log("perviousVendorNames", this.perviousVendorNames)
        if(this.perviousVendorNames == null || this.perviousVendorNames == undefined || this.perviousVendorNames.length == 0){
            return false
        }
        if(this.vendorsObj._id){
            let a = false;
            a = this.filteredVendorNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedVendorNames);
            return a
        }else{
            let a = false;
            a = this.perviousVendorNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedVendorNames);
            return a
        }
    },
    checkLegalName: function (): any {
        const trimmedlegalName = this.vendorsObj.legalName.trim().replace(/\s+/g, '').toLowerCase();

        if(this.perviousLegalNames == null || this.perviousLegalNames == undefined || this.perviousLegalNames.length == 0){
            return false
        }
        if(this.vendorsObj._id){
            let a = false;
            a = this.filteredLegalNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedlegalName);
            return a
        }else{
            let a = false;
            a = this.perviousLegalNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedlegalName);
            return a
        }
    },
    checkWebsiteLinks: function (): any {
        const trimmedWebsiteLinks = this.vendorsObj.website.trim().toLowerCase();

        const domainName = trimmedWebsiteLinks.replace(/^https?:\/\//, '').toLowerCase();

        if(this.perviousWebsiteLinks == null || this.perviousWebsiteLinks == undefined || this.perviousWebsiteLinks.length == 0){
            return false
        }
        if(this.vendorsObj._id){
            let a = false;
            a = this.filteredWebsiteLinks.map((name: any) => name.replace(/^https?:\/\//, '').toLowerCase()).includes(domainName);
            return a
        }else{
            let a = false;
            a = this.perviousWebsiteLinks.map((name: any) => name.replace(/^https?:\/\//, '').toLowerCase()).includes(domainName);
            return a
        }
    },
  },
  methods: {
    asyncVendorCatgeoryData(query:any) {
      this.searchValue = query
			query = query.trim();
			if (query == '') {
				this.vendorCatgeoryDisplay = [];
			} else {
				this.vendorCatgeoryDisplay = this.vendorCatgeoryList.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.vendorCatgeoryList.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.vendorCatgeoryDisplay.push(query);
				}
			}
		},
    filterOpen() {
      this.showFilters = !this.showFilters;
      if (this.showFilters == true) {
        this.dummyList = this.vendorsArray;
      } else {
        this.vendorsArray = this.dummyList;
      }
      emitter.emit("visible", this.showFilters);
    },
    showAddPopup() {
        this.showVendorsPopUp = true;
        this.vendorsObj = {
            name: "",
            vendorCategory: "",
            legalName: "",
            website: "",
            imageUrl: "",
        };
        this.dummyVendor = {...this.vendorsObj}
    },
    showEditPopup(data: any) {
        this.vendorsObj = this.vendorsArray.find((obj:any)=>{return obj._id == data._id})
        this.dummyVendor = {...this.vendorsObj};
        this.filteredVendorNames = this.perviousVendorNames.filter((name:any)=>{
            return name != this.vendorsObj.name
        })
        this.filteredLegalNames = this.perviousLegalNames.filter((name:any)=>{
            return name != this.vendorsObj.legalName
        })
        this.filteredWebsiteLinks = this.perviousWebsiteLinks.filter((name:any)=>{
            return name != this.vendorsObj.website
        })

        this.presentImageUrl = this.vendorsObj.imageUrl
        this.showVendorsPopUp = true;
    },
    onCancelbtn(prop:any){
        this.v$.$reset();
        if(prop == 'close'){
            this.showVendorsPopUp  = false;
            this.fileName = ''
        }

        if(this.fileName != ''){
            this.fileName = ''
        }
        this.vendorsObj = {...this.dummyVendor};
    },
    updateFileName(fileInput: any, type: any) {
        let file = [];
        this.fileName = '';
        
        file = this.$refs[fileInput].files[0];

        console.log('myfile',file)
        
        if (file != undefined) {
            this.imgCheck(file, type);
        }
    },
    async addFramework() {
        this.v$.$touch();
        if (!this.v$.vendorsObj.$invalid && !this.v$.fileName.$invalid && this.checkVendorName == false && this.checkLegalName == false && this.checkWebsiteLinks == false) {
            this.clickOnButton = true;
            let payload:any = {
                "name": this.vendorsObj.name,
                "legalName": this.vendorsObj.legalName,
                "vendorCategory": this.vendorsObj.vendorCategory,
                "website": this.vendorsObj.website,
                "imageUrl": ""
            }
            this.dataLoading = true
            await this.uploadToS3AndGetLink(this.presentFile);
            payload.imageUrl = this.presentImageUrl
            console.log("presentImageUrl", this.presentImageUrl);
            if(payload.imageUrl != ''){
                await this.addNewVendors(payload);
            }
            console.log("payload", payload)
            this.dataLoading = false;
        } else {
            toast.error(`Error`, {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        };
    },
    async addNewVendors(payload:any){
        await this.$http.post(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/create/20003`, payload).then(async (response: any) => {
            this.presentFile = '';
            this.presentImageUrl  = '';
            await this.getAllVendors();
            await this.getAllVendorsRoot()
            this.onCancelbtn('close')
            toast.info('Saved', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });	
        })
        .catch((error: any) => {
            toast.error('Error', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
        });
    },
    async editFramework(){
        this.v$.vendorsObj.$touch();
        if (!this.v$.vendorsObj.$invalid && this.checkVendorName == false) {
            let presentId = this.vendorsObj._id;
            let payload:any = {
                "name": this.vendorsObj.name,
                "legalName": this.vendorsObj.legalName,
                "vendorCategory": this.vendorsObj.vendorCategory,
                "website": this.vendorsObj.website,
                "imageUrl": this.vendorsObj.imageUrl
            }

            if(Object.keys(this.vendorsObj).includes('imageChanged')){
                await this.uploadToS3AndGetLink(this.presentFile);

                payload.imageUrl = this.presentImageUrl;
            }

            await this.updatePresentVendor(payload,presentId);
            
        }else{
            console.log('this.v$.system.$invalid',!this.v$.vendorsObj.$invalid)
            console.log('this.checkVendorName == false',this.checkVendorName == false)
        }

    },
    async updatePresentVendor(payload:any, presentId:any){
        await this.$http
            .post(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/update/${presentId}/20003`, payload)
            .then(async (response: any) => {
                this.presentFile = '';
                this.presentImageUrl  = '';
                this.onCancelbtn('close')
                await this.getAllVendors();
                await this.getAllVendorsRoot()
                toast.info('Updated', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });	
            })
            .catch((error: any) => {
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            });
        },
    async uploadToS3AndGetLink(file: any) {
        // this.spinnerLoading = true;
        const bufferData = await this.uploadEncodedDataFormat(file);
        let fileExtension = file.name.split('.').pop();
        let id= uuidv4();

        let payload = { fileData: bufferData, name: `${id}.${fileExtension}`};
        try {
            await this.$http.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${process.env.VUE_APP_FOLDER_NAME}/file/upload`, payload).then((response: any) => {
                if (response.status == 200) {
                    console.log("response", response.data);
                    this.presentImageUrl = response.data;
                    // this.spinnerLoading = false;
                } else {
                    console.log('response status', response.status);
                }
            });
        } catch (err) {
            console.error(err);
        }
    },
    async uploadEncodedDataFormat(data: any) {
        return new Promise((resolve) => {
            const reader: any = new FileReader();
            reader.onload = function () {
                resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
            };
            reader.readAsDataURL(data);
        });
    },
    imgCheck(file: any, type: any) {
        if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'image/svg') {
            let size = Number((file.size / 1048576).toFixed(2));
            if (size > 80) {
                toast.error('Image should be atmost 80 MB', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
                this.resetFileUploads()
            } 
            else {
                this.fileName = file.name;
                this.presentFile = file;
                // if(type == 'edit'){
                    this.vendorsObj.imageChanged = true
                // }
            }
        } else {
            toast.error('Supported only jpg, jpeg, png, svg', {
                timeout: 1000,
                closeOnClick: true,
                closeButton: 'button',
                icon: true
            });
            this.resetFileUploads()
        }
    },
    resetFileUploads(){
        if(this.$refs.fileInput.value){
            this.$refs.fileInput.value='';
        }
    },
    async getAllVendors() {
      this.vendorsDataArray = [];
      this.vendorCatgeoryList = [];
      this.dataLoading = true;
      await this.$http.get(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/getall/20003`).then((res: any) => {
        this.vendorsArray = res.data
        this.vendorsArray.map((obj: any) => {
          let newObj = {
            _id: obj._id,
            name: obj.name != undefined ? obj.name : "N/A",
            legalName: obj.legalName != undefined ? obj.legalName : "N/A",
            vendorCategory: obj.vendorCategory != undefined ? obj.vendorCategory : 'N/A',
            website: obj.website != undefined ? obj.website : 'N/A'
          };
          this.vendorsDataArray.push(newObj);
          if(!this.vendorCatgeoryList.includes(obj.vendorCategory)){
            this.vendorCatgeoryList.push(obj.vendorCategory)
          }
          this.dataLoading = false;
        });
      })
    },
    async getAllVendorsRoot(){
        this.vendorsArray.map((obj:any)=>{
            this.perviousVendorNames.push(obj.name)
            this.perviousLegalNames.push(obj.legalName)
            this.perviousWebsiteLinks.push(obj.website)
        })
    }
  },
  async created() {
    this.dataLoading = true;
    await this.getAllVendors();
    await this.getAllVendorsRoot()
    this.dataLoading = false;
  },
  async mounted() {
    this.dummyList = [...this.vendorsArray];
    emitter.off("dataTransfering");
    emitter.on("dataTransfering", (isData: any) => {
      this.showEditPopup(isData);
    });
    emitter.off("activiteToolTip");
    emitter.on("activiteToolTip", (data: any) => {
      this.toolTipActive = true;
      this.toolTipText = data.tipText;
    });

    emitter.off("deActiviteToolTip");
    emitter.on("deActiviteToolTip", (data: any) => {
      this.toolTipActive = false;
      this.toolTipText = "";
    });
  },
});
</script>

