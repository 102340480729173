import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6b32aed1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer z-99 place-items-center w-full fixed bottom-0 h-8 border-t border-solid border-lightgrey bg-white shadow-2xl" }
const _hoisted_2 = { class: "flex justify-center items-center p-2" }
const _hoisted_3 = { class: "text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, "© " + _toDisplayString(_ctx.year) + " Crystalline Software Technologies Pvt. Ltd", 1)
    ])
  ]))
}