<template>
		<div class="navbar bg-white p-0 sticky z-50 top-0 left-0 flex shadow">
		<div class="w-full flex justify-center  items-center">
			<div class="lg:flex cursor-pointer">
				<span class="text-lg font-bold cursor-pointer">
					<img src="@/assets/dynematrix.svg" alt="" class="w-40" />
				</span>
			</div>
		</div>
	</div>
</template>
<style scoped>
.rounded-full:hover{
	box-shadow: 0px 0px 3px 4px rgba(0, 0, 0, 0.1);
}
.navbar{
	min-height: 2.5rem;
	height: 40px;
}

.right-4{
	box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.08);
	/* background-color: red; */
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter,toast } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	data():any {
		return {
			isToggle: true,
			isappsToggle:false,
			showBlock: false,
			displayName:'',
		};
	},
});
</script>