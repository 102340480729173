<template>
    <!-- <div class="w-full h-screen paddingContainer pt-5"> -->
        <!-- <div class="bg-white border border-lightgrey rounded infoContainer overflow-y-auto">

            <div v-if="!show" class="text-center">
                <div class="text-primary text-md mt-2 font-semibold mb-1">Welcome to DYNEMATRIX !</div>
                <div class="text-disabledgrey text-xs mb-2">To get the best experience, we recommend you to complete these Onboarding steps</div>
            </div>

            <Transition name="dynematrix-text-start">
                <div v-if="show" class="text-center">
                    <div class="text-primary text-md mt-2 font-semibold mb-1">Welcome to DYNEMATRIX !</div>
                    <div class="text-disabledgrey text-xs mb-2">To get the best experience, we recommend you to complete these Onboarding steps</div>
                </div>
            </Transition>

            <div v-if="!show" class="text-center">
                <div v-for="tab in tabHead" :key="tab.id" class="w-3/4  box py-1 border border-lightgrey mb-2 mx-auto rounded-sm">
                    <div class="text-sm font-semibold mb-1">{{ tab.name }}</div>
                    <div class="text-xs text-onBoardBlue">{{ tab.statement }}</div>
                </div>
            </div>

            <Transition name="start-tabs">
                <div v-if="show" class="text-center">
                    <div v-for="tab in tabHead" :key="tab.id" class="w-3/4 box py-1 border border-lightgrey mb-2 mx-auto rounded-sm">
                        <div class="text-sm font-semibold mb-1">{{ tab.name }}</div>
                        <div class="text-xs text-onBoardBlue">{{ tab.statement }}</div>
                    </div>
                </div>
            </Transition>

            <div class="flex justify-center items-center">
                <div class="w-3/4 flex justify-between items-center">
                    <button @click="goBack()" class="btnprimary strokeButton h-7 w-16 flex justify-center items-center" >Cancel</button>
                    <button @click="startOnBoardProcess()" class="btnprimary nextButton h-7 w-16 flex justify-center items-center" >Start</button>
                </div>
            </div>
        </div> -->
    <!-- </div> -->
    <div class="flex flex-col items-center w-full h-full paddingContainer overflow-y-auto">

        <div class="w-auto mt-3">
            <h1 class="text-primary text-md text-center">Welcome to DYNEMATRIX !</h1>
            <p class="text-primary text-xs text-center mt-1">To get the best experience, we recommend you to complete these onboarding steps</p>
        </div>
 
        <div class="bg-white overflow-y-auto flex flex-col justify-between border mt-3 border-lightgrey rounded infoContainer w-full p-3">

            <!-- <Transition name="dynematrix-text-start">
                <div v-if="show" class="text-center my-1">
                    <div class="text-primary text-md font-semibold">Welcome to DYNEMATRIX !</div>
                    <div class="text-disabledgrey text-xs mt-1">To get the best experience, we recommend you to complete these Onboarding steps</div>
                </div>
            </Transition> -->

            <!-- <div > -->
                <div v-for="tab in tabHead" :key="tab.id" class="w-3/4 box border border-lightgrey mb-2 mx-auto rounded-sm">
                    <div class="flex flex-col justify-center items-center h-full">
                        <div class="text-sm font-semibold mb-1">{{ tab.name }}</div>
                        <div class="text-xs text-onBoardBlue">{{ tab.statement }}</div>
                    </div>
                </div>
            <!-- </div> -->

            <!-- <Transition name="start-tabs">
                <div v-if="show">
                    <div v-for="tab in tabHead" :key="tab.id" class="w-3/4 box py-1 border border-lightgrey mb-2 mx-auto rounded-sm">
                        <div class="flex flex-col justify-center items-center h-full">
                            <div class="text-sm font-semibold mb-1">{{ tab.name }}</div>
                            <div class="text-xs text-onBoardBlue">{{ tab.statement }}</div>
                        </div>
                    </div>
                </div>
            </Transition> -->
 
            <div class="flex justify-center items-center">
                <div class="w-3/4 flex justify-between items-center">
                    <button @click="goBack()" class="btnprimary strokeButton h-7 w-16 flex justify-center items-center" >Cancel</button>
                    <button @click="startOnBoardProcess()" class="btnprimary nextButton h-7 w-16 flex justify-center items-center" >Start</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@media only screen and (min-width: 850px) {
  .box{
    height: 10vh;
}
}
.strokeButton{
    background-color: #ffffff;
    border: 1px solid #119BB9;
    color: #119BB9;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
}
.nextButton {
    background-color: #119BB9;
    border: 1px solid #119BB9;
    color: #ffffff;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
}
.infoContainer{
    height: 74vh;
}
.paddingContainer{
    padding-left: 10%;
    padding-right: 10%;
}
.infoContainer::-webkit-scrollbar {
    width: 2px;
}

.start-tabs-enter-active,
.start-tabs-leave-active {
  transition: opacity 0.5s ease-in;
}

.start-tabs-enter-from {
  opacity: 1;
}

.start-tabs-leave-to {
  opacity: 0;
}

.dynematrix-text-start-enter-active,
.dynematrix-text-start-leave-active {
  transition: transform 1s linear;
}

.dynematrix-text-start-enter-from,
.dynematrix-text-start-leave-to {
  transform: translateY(0);
}

.dynematrix-text-start-enter-to {
  transform: translateY(-50%);
}

.dynematrix-text-start-leave-from {
  transform: translateY(0);
}

.dynematrix-text-enter-active {
  transition: all 0.8s ease-out;
}

</style>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    data() :any{
        return {
            name:'aiou',
            tabHead:[
                { id:1, name:"Organization", statement:"Add details of a Authorized for whole organization",isCompleted: false},
                { id:2, name:"Teams", statement:"Info of the groups that are in that particular organization",isCompleted: false},
                { id:3, name:"Activities", statement:"Flows or process followed by Organization",isCompleted: false},
                { id:4, name:"Systems", statement:"Info of an external entity that provides goods or services",isCompleted: false},
                { id:5, name:"Vendors", statement:"Info of an external entity that provides goods or services",isCompleted: false},
                { id:6, name:"Frameworks", statement:"Frameworks are for managing an organization's information security",isCompleted: false},
            ],
            show:false,
        }
    },
    methods:{
        startOnBoardProcess(){
            this.show = true;
            // setTimeout(() => {
                this.$router.push({ name: 'process-onBoard' });
            // }, 800); 
        },
        goBack(){
            this.$router.push({ name: 'organisations' });
        }
    }
})
</script>
